import { FC, useEffect } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'

import { jwtDecode } from 'jwt-decode'

import { EAuthQueryParams } from '@/components/app/libs/enums/auth-query-params.enum'
import { CustomToast } from '@/components/custom-toast'
import { useSelectNewToken } from '@/hooks/useSelectNewToken.hook'
import { authRoutes } from '@/libs/configs/auth-routes.config'
import { DEFAULT_TOKEN } from '@/libs/configs/default-token.config'
import { AppRoute, LocalStorageItem } from '@/libs/enums'
import { isAppMode } from '@/libs/helper/isAppMode'
import { useAppDispatch } from '@/store'
import { checkBrowserSupport } from '@/store/slices/app.slice'
import { fetchUserBuyTemplates, fetchUserById } from '@/store/slices/user.slice'

type TProps = {
  setIsAppLoaded: (value: boolean) => void
}

const AuthController: FC<TProps> = ({ setIsAppLoaded }) => {
  const dispatch = useAppDispatch()

  const [searchParams] = useSearchParams()
  const location = useLocation()

  const handleNewTokenSelection = useSelectNewToken()
  const routesArray = location.pathname.split('/dashboard/')
  const routeAppMode = routesArray.length > 1 && isAppMode(routesArray[1]) ? routesArray[1] : null

  const loadApp = () => {
    console.log(authRoutes.includes(location.pathname))
    console.log(location.pathname)
    const tokenFromUrl = searchParams.get('token')
    const cachedToken = localStorage.getItem(LocalStorageItem.TOKEN_ADDRESS)
    const initialToken = tokenFromUrl || cachedToken || DEFAULT_TOKEN
    const storedAppMode = localStorage.getItem(LocalStorageItem.MODE)
    handleNewTokenSelection(initialToken, {
      replaceNavigation: true,
      skipRedirect:
        location.pathname === AppRoute.ADD_DEVICE ||
        (!authRoutes.includes(location.pathname) && routeAppMode == storedAppMode),
      customAppMode: storedAppMode || routeAppMode,
      isForceNavigate: !!storedAppMode && routeAppMode !== storedAppMode,
    })

    setIsAppLoaded(true)
  }

  const handleAuth = async () => {
    const isBrowserSupported = await dispatch(checkBrowserSupport()).unwrap()

    if (!isBrowserSupported) {
      return
    }

    const token = localStorage.token as string | undefined

    if (!token) {
      loadApp()
      return
    }

    const decoded = jwtDecode(token)
    const userId = decoded?.sub as string

    try {
      const userData = await dispatch(fetchUserById(userId)).unwrap()

      if (!userData) {
        loadApp()
        return
      }

      dispatch(fetchUserBuyTemplates())
      loadApp()
    } catch (err) {
      CustomToast('error', (err as any).message)
      loadApp()
    }
  }

  useEffect(() => {
    const affiliateId = searchParams.get(EAuthQueryParams.AFFILIATE_ID)

    if (affiliateId) {
      sessionStorage.setItem(EAuthQueryParams.AFFILIATE_ID, affiliateId)
    }

    handleAuth()
  }, [])

  return <></>
}

export { AuthController }
