import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import cn from 'classnames'

import { RemoveHoneypotBtn } from '@/components/remove-honeypot-btn'
import { SellPopup } from '@/components/sell-popup'
import styles from '@/components/sidebar/libs/components/holdings-accordion/styles.module.scss'
import { useSelectNewToken } from '@/hooks/useSelectNewToken.hook'
import {
  AccordionListElement,
  ButtonIcon,
  Icon,
  type TOnChangeVisible,
  Typography,
} from '@/libs/common'
import { AppMode, AppRoute, IconName } from '@/libs/enums'
import { createSellPayload, formatNumber, handleSell } from '@/libs/helper'
import { formatTokenPrice } from '@/libs/helper/formatTokenPrice'
import { THoldingsToken } from '@/libs/types/sniper-holdings-socket-response.type'
import { TWallet } from '@/libs/types/transaction'
import { useAppSelector } from '@/store'

type TProperty = {
  holding: THoldingsToken
  isNative?: boolean
  isHoneypot?: boolean
}

const HoldingsElement: FC<TProperty> = ({ holding, isNative = false, isHoneypot = false }) => {
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const userWallets = useAppSelector((state) => state.user.userWallets)
  const userConfirmationRequired = useAppSelector((state) => state.user.userConfirmationRequired)
  const navigate = useNavigate()

  const [sellPopup, setSellPopup] = useState<TOnChangeVisible>({
    visible: false,
    anchorE: undefined,
  })

  const handleNewTokenSelection = useSelectNewToken()

  const selectToken = () => {
    if (isNative) {
      return
    }
    handleNewTokenSelection(holding.a)
  }

  const handlePopUp = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setSellPopup({ visible: true, anchorE: event.currentTarget })
  }

  const handleMarketSellAll = async () => {
    const wallets = holding.w.reduce((acc, item) => {
      const wallet = userWallets?.find(
        (userWallet) => userWallet.address.toLowerCase() === item.a.toLowerCase(),
      )
      if (wallet) {
        acc.push({
          name: wallet.name,
          public_key: wallet.address,
        })
      }
      return acc
    }, [] as TWallet[])
    const payload = await createSellPayload({
      token: holding.a,
      wallets,
      sellPercentage: 100,
    })
    if (payload) {
      if (userConfirmationRequired) {
        navigate(
          `${AppRoute.DASHBOARD}/${AppMode.PRO}/${AppRoute.MODAL}/${AppRoute.APPROVE_SELL}`,
          {
            state: {
              payload,
              data: {
                receive: holding.tvc || 0,
                sell: holding.tb || 0,
                tokenName: holding.s,
              },
            },
          },
        )
      } else {
        await handleSell(payload)
      }
    }
  }

  return (
    <AccordionListElement
      enableBurger={false}
      className={cn({ [styles.highlighted]: sellPopup.visible })}
    >
      <div className={styles.content} onClick={selectToken}>
        <div className={styles.coinWrapper}>
          <div className={cn(styles.coin, { [styles.native]: isNative })}>
            <Typography variant="body2" className={styles.coinQuantity}>
              {formatTokenPrice((holding.tb || 0).toString()).formatted}
            </Typography>
            <Typography variant="body2" className={styles.coinName}>
              {holding.s}
            </Typography>
          </div>
          {!!holding.tvc && !isNative && (
            <div className={styles.currencyWrapper}>
              <Typography variant="body2" className={styles.currencyValue}>
                {isHoneypot ? '-' : formatTokenPrice(holding.tvc).formatted}
              </Typography>
              <Typography variant="body2" className={styles.currency}>
                {currentChain.description}
              </Typography>
            </div>
          )}
        </div>
        {isHoneypot && <RemoveHoneypotBtn token={holding.a} />}
        <div className={cn(styles.info, { [styles.honeypot]: isHoneypot })}>
          <div className={styles.values}>
            <Typography variant="body2" className={styles.price}>
              ${!isHoneypot && holding.tvu ? formatNumber(holding.tvu).formatted : ' -'}
            </Typography>
            {/* <Typography variant="body2" className={styles.percents}>
              <Icon name={IconName.ARROW_DROP_DOWN} />
              {holding.statistic}
            </Typography> */}
          </div>
          {!isNative && !isHoneypot && (
            <div className={styles.popupContainer}>
              <ButtonIcon onClick={handlePopUp}>
                <Icon name={IconName.SELL} />
                <Typography variant="body2" textColor="gold">
                  Sell
                </Typography>
              </ButtonIcon>
            </div>
          )}
        </div>
      </div>
      <SellPopup
        onChangeVisible={setSellPopup}
        popoverState={sellPopup}
        tokenAddress={holding.a}
        onMarketSellAll={handleMarketSellAll}
      />
    </AccordionListElement>
  )
}

export { HoldingsElement }
