import { useEffect, useMemo, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid } from '@mui/material'
import * as yup from 'yup'

import { getDefaultTemplate } from '@/components/buy-tab/libs/utils/get-default-template'
import { TemplatesControl } from '@/components/templates-control'
import { useWindowDimensions } from '@/hooks/useWindowDimensions'
import { Button, Icon, Typography } from '@/libs/common'
import { EndAdornment } from '@/libs/common/input/components/end-adornment'
import { OptionalInput } from '@/libs/common/optional-input'
import { MAX_TRX_DECIMALS } from '@/libs/configs/transactions.config'
import { IconName } from '@/libs/enums'
import { convertScientificNotationNumber } from '@/libs/helper/convertScientificNotationNumber'
import { TBuyTemplate } from '@/libs/types/template'
import { stringOfNumbersValidation } from '@/libs/validations/common'
import { useModal } from '@/pages/modal-page/modal-page'
import { useAppDispatch, useAppSelector } from '@/store'
import { setQuickBuySettings } from '@/store/slices/user.slice'

import styles from './styles.module.scss'

const manualTemplate = {
  id: 'Manual',
  name: 'Manual',
  isPredefined: true,
}

const requiredTemplateAmountMsg =
  "Purchase amount not specified in template. Please enter the amount in the 'Spend' section."

const QuickBuySettings = () => {
  const userData = useAppSelector((state) => state.user.userData)
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const [currentTemplate, setCurrentTemplate] = useState<TBuyTemplate | null>(null)
  const userTemplates = useAppSelector((state) => state.user.userTemplates.buyTemplates)
  const [defaultCustomTemplate, setDefaultCustomTemplate] = useState<typeof manualTemplate>()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { id: templateId } = useParams()
  const { setModalProps } = useModal()

  const templates = useMemo(() => {
    const defaultTemplates = [manualTemplate]
    if (defaultCustomTemplate) {
      defaultTemplates.push(defaultCustomTemplate)
    }

    return [
      ...defaultTemplates,
      ...(userTemplates?.filter(
        (item) => item.setup.network.blockchain === currentChain.indexerChainId,
      ) || []),
    ]
  }, [userTemplates, defaultCustomTemplate, currentChain])

  useEffect(() => {
    const defaultCustomTemplate = getDefaultTemplate()

    if (defaultCustomTemplate) {
      setDefaultCustomTemplate(defaultCustomTemplate)
    }
  }, [])

  const schema = yup.object({
    spend: stringOfNumbersValidation.required(requiredTemplateAmountMsg),
  })

  const defaultValues = {
    spend: '',
  }
  const {
    handleSubmit,
    control,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema) as any,
  })
  const values = getValues()

  useEffect(() => {
    setModalProps({
      title: 'Quick buy settings',
      titleProps: { className: styles.title },
      withBackButton: false,
    })
  }, [templateId])

  useEffect(() => {
    if (currentTemplate?.id && values.spend) {
      clearErrors('spend')
    }
  }, [values.spend])

  const checkTemplateAmountField = (templateId: string) => {
    const selectedTemplate = templates.find(
      (template) => template.id === templateId,
    ) as TBuyTemplate
    const templateSpendAmount = selectedTemplate?.setup?.operation.setup.buy_amount

    if (templateSpendAmount) {
      setValue('spend', templateSpendAmount ? templateSpendAmount.toString() : '')
      clearErrors('spend')
    } else {
      setError('spend', { message: requiredTemplateAmountMsg })
      setValue('spend', '')
    }
  }

  const handleTemplateChange = (id: string) => {
    const newSelectedTemplate = templates.find((template) => template.id === id) as TBuyTemplate
    if (newSelectedTemplate) {
      checkTemplateAmountField(newSelectedTemplate.id)
      setCurrentTemplate(newSelectedTemplate)
    }
  }

  const onSubmit: SubmitHandler<typeof defaultValues> = (data) => {
    if (!userData) return

    dispatch(
      setQuickBuySettings({
        settings: {
          spend: data.spend,
          templateId: currentTemplate?.id || manualTemplate.id,
        },
      }),
    )
    navigate(-1)
  }

  const windowDimension = useWindowDimensions()
  const isResponsive = windowDimension.width <= 630

  return (
    <div>
      <Grid className={styles.content}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container rowGap={3}>
            <Grid rowGap={2} width="100%">
              <TemplatesControl
                templates={templates}
                currentTemplateId={currentTemplate?.id || manualTemplate.id}
                onSelectTemplate={handleTemplateChange}
                isShowTemplatesOnly={true}
                isResponsive={isResponsive}
              />
            </Grid>
            {errors.spend?.message && !values.spend && (
              <Box className={styles.spendError} display="flex" gap={1}>
                <Icon name={IconName.INFO_ERROR} />
                <Typography variant="body1" textColor="red">
                  {errors.spend?.message}
                </Typography>
              </Box>
            )}
            <Controller
              name="spend"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <OptionalInput
                  {...field}
                  className={styles.input}
                  label="Spend"
                  placeholder="Enter amount to spend"
                  isNumeric
                  endAdornment={<EndAdornment label="ETH" icon={IconName.ETHEREUM} />}
                  // error={!!errors.spend?.message && !!field.value}
                  onChange={(e: any) => {
                    const value = convertScientificNotationNumber(
                      e.target.value,
                      MAX_TRX_DECIMALS,
                      true,
                    )
                    setValue('spend', `${value}`, {
                      shouldValidate: !!value,
                    })
                    if (!value) {
                      clearErrors('spend')
                      return
                    }
                  }}
                />
              )}
            />
            <Button
              disabled={!!errors.spend?.message || (!Number(values.spend) && values.spend !== '')}
              type="submit"
              className={styles.button}
            >
              Save
            </Button>
          </Grid>
        </form>
      </Grid>
    </div>
  )
}

export { QuickBuySettings }
