import moment from 'moment'

import { getBars } from '@/api/chart'
import {
  Bar,
  GetMarksCallback,
  HistoryCallback,
  Mark,
  OnReadyCallback,
  PeriodParams,
  ResolutionString,
  ResolveCallback,
  SubscribeBarsCallback,
} from '@/charting_library/charting_library'
import { MARKS_CONFIG, SHORT_INTERVALS } from '@/components/trading-view-new/libs/configs'
import { TCustomSymbolInfo, TSymbolData } from '@/components/trading-view-new/libs/types'
import { ETxType } from '@/libs/enums'
import { createQueryString } from '@/libs/helper/createQueryString'
import { removeTrailingZeros } from '@/libs/helper/removeTrailingZeros'
import { SniperSockerService } from '@/socket'
import { store } from '@/store'

import { RESOLUTION_MAP, supportedResolutions } from '../../configs'
import { TDatafeedSubscriber } from './types'

type TSetBarsFunc = (bars: Bar[]) => void
type TOnSocketMessage = (lastBar: Bar) => void

type TProps = {
  onBarsUpdate?: TSetBarsFunc
  onSocketMessage?: TOnSocketMessage
}

class Datafeed {
  barsSniperSocket: SniperSockerService
  isWebsocletConnected = false
  subscriber: TDatafeedSubscriber | null = null
  lastTokenAddress: string | null = null
  pairNameChanged = false
  onBarsUpdate: TSetBarsFunc | null = null
  onSocketMessage: TOnSocketMessage | null = null
  currentBars: Bar[] = []

  constructor(props?: TProps) {
    const { onBarsUpdate, onSocketMessage } = props || {}
    this.onBarsUpdate = onBarsUpdate || null
    this.onSocketMessage = onSocketMessage || null
    this.barsSniperSocket = new SniperSockerService()
  }

  // This is a custom method that should be called when a TradingView is mounted
  connectToWebsocket = async () => {
    if (this.isWebsocletConnected) {
      return Promise.resolve(null)
    }

    return new Promise((resolve, reject) => {
      this.barsSniperSocket.connect({
        endpoint: 'token/stream/bars',
        query: createQueryString({
          b: store.getState().chain.currentChain.indexerChainId,
        }),
        isPublic: true,
        onOpen: () => {
          this.isWebsocletConnected = true
          resolve(null)
        },
      })

      this.barsSniperSocket.onError(() => {
        this.isWebsocletConnected = false
        reject(null)
      })
    })
  }

  // This is a custom method that should be called when a TradingView is unmounted
  removeDatafeed = () => {
    this.barsSniperSocket.disconnect()
    this.isWebsocletConnected = false
  }

  // This method is called once the TradingView is mounted
  onReady = (cb: OnReadyCallback) => {
    setTimeout(
      () =>
        cb({
          supported_resolutions: supportedResolutions,
          supports_marks: true,
        }),
      0,
    )
  }

  // This method is called when user changes token or resolution
  resolveSymbol = async (symbolJson: string, onSymbolResolvedCallback: ResolveCallback) => {
    const { tokenAddress, tokenSymbol, calledToSetPairName } = JSON.parse(symbolJson) as TSymbolData

    const symbolInfo: TCustomSymbolInfo = {
      ticker: tokenSymbol || tokenAddress,
      name: tokenSymbol ? tokenSymbol.split('/')[0] : tokenAddress,
      description: '',
      type: 'crypto',
      session: '24x7',
      timezone: 'Etc/UTC',
      exchange: '',
      listed_exchange: '',
      format: 'price',
      minmov: 1,
      pricescale: 10 ** 16,
      has_intraday: true,
      intraday_multipliers: ['1', '5', '15', '60', '240', '720'] as ResolutionString[],
      // TODO: Check if these fields are needed
      // has_weekly_and_monthly: true,
      // has_daily: true,
      // daily_multipliers: ['1', '7'],
      // has_seconds: true,
      // seconds_multipliers: ['1', '15'] as ResolutionString[],
      supported_resolutions: supportedResolutions,
      volume_precision: 8, // 2
      data_status: 'streaming',
      tokenMeta: {
        tokenAddress,
        calledToSetPairName,
      },
    }

    if (this.lastTokenAddress !== tokenAddress) {
      this.currentBars = []
    }

    this.pairNameChanged = false
    this.lastTokenAddress = tokenAddress

    setTimeout(() => onSymbolResolvedCallback(symbolInfo), 0)
  }

  // This method is called after the resolveSymbol to get historical data for a token
  getBars = async (
    symbolInfo: TCustomSymbolInfo,
    resolution: ResolutionString,
    periodParams: PeriodParams,
    onHistoryCallback: HistoryCallback,
    onErrorCallback: ErrorCallback,
  ) => {
    try {
      const {
        tokenMeta: { tokenAddress, calledToSetPairName },
      } = symbolInfo
      const { from, to } = periodParams

      if (calledToSetPairName && !this.pairNameChanged) {
        this.pairNameChanged = true
        onHistoryCallback(this.currentBars, { noData: !this.currentBars.length })
        return
      }

      const bars = await getBars({
        from,
        to,
        resolution,
        tokenAddress,
        blockchain: store.getState().chain.currentChain.indexerChainId,
      })

      if (bars.length) {
        this.currentBars = [...bars, ...this.currentBars]
        this.onBarsUpdate?.(this.currentBars)
      }
      onHistoryCallback(bars, { noData: !bars.length })
    } catch (err) {
      onErrorCallback(err as any)
    }
  }

  // This method is called when the TradingView asks for custom marks (user trades in our case)
  getMarks = (
    _: TCustomSymbolInfo,
    startDate: number,
    endDate: number,
    onDataCallback: GetMarksCallback<Mark>,
    resolution: ResolutionString,
  ) => {
    const currentToken = store.getState().chain.currentToken
    const isSmallResolution = SHORT_INTERVALS.includes(resolution)

    if (
      !store.getState().user.userData ||
      !store.getState().app.showUserTradesOnChart ||
      !this.currentBars.length ||
      isSmallResolution
    ) {
      onDataCallback([])
      return
    }

    // TODO: Display in tokens that were bought
    const chainCurrency = store.getState().chain.currentChain.description

    const marks =
      store.getState().favoritesTokens.orderHistory?.reduce((acc, order) => {
        if (order.ta !== currentToken?.info?.address) {
          return acc
        }

        const orderTimeStamp = new Date(order.d).getTime() / 1000 + moment().utcOffset() * 60

        if (
          orderTimeStamp > endDate ||
          orderTimeStamp < startDate ||
          order.ty === ETxType.APPROVE ||
          order.s !== 'Completed'
        ) {
          return acc
        }

        const orderConfig = MARKS_CONFIG[order.ty]

        acc.push({
          id: order.id,
          time: orderTimeStamp,
          color: orderConfig.color,
          text: `${orderConfig.textLabel} ${+order.tam === 0 ? '-' : removeTrailingZeros(order.tam)} ${chainCurrency} on ${moment(order.d).format('MMM DD HH:mm')}`,
          label: orderConfig.label,
          labelFontColor: 'white',
          minSize: 25,
        })

        return acc
      }, [] as Mark[]) || []

    onDataCallback(marks)
  }

  // This method is called after the resolveSymbol to subscribe to a new token/resolution
  subscribeBars = (
    symbolInfo: TCustomSymbolInfo,
    resolution: ResolutionString,
    onRealtimeCallback: SubscribeBarsCallback,
    listenerGuid: string,
  ) => {
    const currentToken = store.getState().chain.currentToken
    if (!currentToken) return

    this.barsSniperSocket.onMessage((jsonData) => {
      const { data } = JSON.parse(jsonData)
      const resolutionKey = RESOLUTION_MAP[resolution as keyof typeof RESOLUTION_MAP]
      if (data[resolutionKey]) {
        const ohlcvt = data[resolutionKey].u
        if (ohlcvt) {
          const currentLastBar = {
            time: ohlcvt.t * 1000,
            high: ohlcvt.h,
            low: ohlcvt.l,
            open: ohlcvt.o,
            close: ohlcvt.c,
            volume: ohlcvt.v,
          }

          this.currentBars = [...this.currentBars, currentLastBar]
          onRealtimeCallback(currentLastBar)
          this.onSocketMessage?.(currentLastBar)
        }
      }
    })

    // Don't do anything if the component requested info for the same token
    if (this.subscriber?.symbolInfo.tokenMeta.tokenAddress === symbolInfo.tokenMeta.tokenAddress) {
      return
    }

    // Unsubscribe from the previous token if the component requested a new one
    if (this.subscriber) {
      this.barsSniperSocket.emit(
        JSON.stringify({
          u: this.subscriber.payload.s,
          q: this.subscriber.payload.q,
        }),
      )
    }

    const payload = {
      s: currentToken.pair.address,
      q: currentToken.info?.quote_token || '',
    }
    this.barsSniperSocket.emit(JSON.stringify(payload))

    const newSub = {
      payload,
      listenerGuid,
      resolution,
      symbolInfo,
    }
    this.subscriber = newSub
  }

  // The TradingView library requires this method, but we don't use it
  unsubscribeBars = () => {}
}

export { Datafeed }
