import axios from 'axios'
import { jwtDecode } from 'jwt-decode'

import { api } from '@/api'
import { getCurrentUserId } from '@/libs/helper/getCurrentUserId'
import { TUser } from '@/libs/types'
import { TBackendResponse } from '@/libs/types/backend-response.type'

import { TSignupBody, TUpdateUserBody, TUserLockingBody } from './types'

const BASE_URL = 'api/v1/user'

const getUserById = (userId: string) =>
  api.get<TBackendResponse<TUser>>(`${BASE_URL}/base/${userId}/get?l=en`)

const updateUser = (data: TUpdateUserBody) => {
  const userId = getCurrentUserId()
  return api.post<any>(`${BASE_URL}/base/${userId}/update`, data)
}

const lockUser = (data: TUserLockingBody) => {
  const userId = getCurrentUserId()
  return api.post<any>(`${BASE_URL}/base/${userId}/lock?l=en`, data)
}

const unlockUser = (data: TUserLockingBody) => {
  const userId = getCurrentUserId()
  return api.post<any>(`${BASE_URL}/base/${userId}/unlock?l=en`, data)
}

const reset2FA = (data: object) => {
  const userId = getCurrentUserId()
  return api.post<any>(`${BASE_URL}/base/${userId}/reset2fa`, data)
}
const updateMasterPassword = (data: object) => {
  const userId = getCurrentUserId()
  return api.post<any>(`${BASE_URL}/base/${userId}/resetmaster`, data)
}
const getStateOfUser = (address: string) => api.get(`${BASE_URL}/base/${address}/exists?l=en`)
const getNonce = () => api.get<any>(`${BASE_URL}/authentication/nonce`)
const signIn = (data: object) => api.post<any>(`${BASE_URL}/authentication/signin`, data)
const attachChainToAccount = (userId: string, data: object) =>
  api.post<any>(`${BASE_URL}/authentication/${userId}/indirectattach`, data)
const signUp = (data: TSignupBody) => api.post<any>(`${BASE_URL}/base/signup`, data)

const logout = (userId: string) => api.delete<any>(`${BASE_URL}/authentication/${userId}/signout`)
const validateCode = (userId: string, data: object) =>
  api.post<any>(`${BASE_URL}/base/${userId}/validate2fa`, data)

const updateUser2FA = (data: object) => {
  const userId = getCurrentUserId()
  return api.post<any>(`${BASE_URL}/base/${userId}/update`, data)
}

const getSetup = async (userId: string) => api.get(`${BASE_URL}/setup/${userId}/get?b=1`)
const setSetup = async (userId: string, data: object) =>
  api.post(`${BASE_URL}/setup/${userId}/update`, data)
const refreshTokens = () => {
  const token = localStorage.refreshToken as string | undefined

  if (!token) {
    throw new Error('No refresh token')
  }

  const decoded = jwtDecode(token)
  const userId = decoded?.sub as string

  return axios.get<TBackendResponse<{ token: string }>>(
    `${import.meta.env.VITE_INDEXER_URL}/${BASE_URL}/authentication/${userId}/refresh?l=en`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )
}

export {
  getUserById,
  refreshTokens,
  updateUser,
  getStateOfUser,
  getNonce,
  signIn,
  signUp,
  validateCode,
  updateUser2FA,
  attachChainToAccount,
  logout,
  getSetup,
  setSetup,
  updateMasterPassword,
  reset2FA,
  lockUser,
  unlockUser,
}
