import { FC, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import cn from 'classnames'

import { deleteWebHook } from '@/api/device'
import { Row } from '@/components/user-settings/libs/components/webhooks/row.tsx'
import { Typography } from '@/libs/common'
import { AppRoute } from '@/libs/enums'
import { TWebhook } from '@/libs/types/webhooks.ts'
import { useAppDispatch, useAppSelector } from '@/store'
import { fetchUserWebHooks } from '@/store/slices/user.slice'

import styles from './styles.module.scss'

const Webhooks: FC = () => {
  const userWebHooks = useAppSelector((state) => state.user.userWebHooks)
  const navigate = useNavigate()
  const { mode } = useParams()
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(fetchUserWebHooks())
  }, [])
  const remove = async (id: string) => {
    await deleteWebHook(id)
    await dispatch(fetchUserWebHooks()).unwrap()
  }
  const edit = async (row: TWebhook) => {
    navigate(`${AppRoute.DASHBOARD}/${mode}/${AppRoute.MODAL}/${AppRoute.EDIT_WEBHOOK}`, {
      state: { hook: row },
    })
  }
  return (
    <div>
      <Typography variant="body1" textColor="color-grey-3" marginBottom={1.5}>
        Webhook
      </Typography>
      <TableContainer className={cn(styles.container, styles.containerSmall)} id="webhooks-table">
        <Table stickyHeader aria-label="sticky table" classes={styles}>
          <TableHead>
            <TableRow className={styles.headRow}>
              <TableCell className={cn(styles.tableHeadCell, styles.nameCell)}>Name</TableCell>
              <TableCell className={cn(styles.tableHeadCell, styles.templateCell)}>
                Linked template
              </TableCell>
              <TableCell className={cn(styles.tableHeadCell, styles.urlCell)}>URL</TableCell>
              <TableCell className={cn(styles.tableHeadCell, styles.actionCell)}></TableCell>
            </TableRow>
          </TableHead>
          {userWebHooks.length ? (
            <TableBody>
              {userWebHooks.map((hook: TWebhook) => (
                <Row row={hook} key={hook?.id} removeHook={remove} editHook={edit} />
              ))}
            </TableBody>
          ) : (
            <TableRow className={styles.headRow}>
              <TableCell />
              <TableCell />
              <TableCell>
                <Typography variant="body2" textColor="color-grey-3">
                  You don`t have any webhooks yet
                </Typography>
              </TableCell>
              <TableCell />
            </TableRow>
          )}
        </Table>
      </TableContainer>
    </div>
  )
}

export { Webhooks }
