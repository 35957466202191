import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { Modal } from '@mui/material'

import { Button, Typography } from '@/libs/common'

import styles from './styles.module.scss'

const IdleWarningModal = () => {
  const handleClose = () => {}

  return (
    <Modal
      open
      disableEnforceFocus
      onClose={handleClose}
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
          },
        },
      }}
    >
      <div className={styles.wrapper}>
        <TransitionGroup exit={false}>
          <CSSTransition
            key="IdleWarningModal"
            classNames={{
              enter: styles.animationEnter,
              enterActive: styles.animationEnterActive,
              appear: styles.animationAppear,
              appearActive: styles.animationAppearActive,
            }}
            timeout={300}
            in
            appear
          >
            <div id="idle-modal" className={styles.container}>
              <Typography variant="h1" align="center" className={styles.title}>
                You Have Been Disconnected
              </Typography>
              <Typography align="center" variant="body1" className={styles.message}>
                You have been inactive for 30 minutes, so your app has been disconnected. Please
                reload the page to reconnect.
              </Typography>
              <Button className={styles.reloadBtn} onClick={() => location.reload()}>
                Reload
              </Button>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </div>
    </Modal>
  )
}

export { IdleWarningModal }
