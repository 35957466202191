import { FC } from 'react'
import { useForm } from 'react-hook-form'

import { updateUser } from '@/api/user'
import { UserFormItem, Webhooks } from '@/components/user-settings/libs/components'
import { extractErrorDescription } from '@/libs/helper/extractErrorDescription'
import { useAppDispatch, useAppSelector } from '@/store'
import { fetchUserById } from '@/store/slices/user.slice'

import styles from './styles.module.scss'

const Account: FC = () => {
  const userData = useAppSelector((state) => state.user.userData)

  const dispatch = useAppDispatch()

  const defaultValues = {
    userName: userData?.username || '',
    email: userData?.email || '',
  }

  const { control, getValues } = useForm({
    defaultValues,
  })

  const saveData = async (name: string, field: string) => {
    if (!userData) return
    try {
      const value = ((getValues() as any)[name] || '').trim()
      if (!value) return
      await updateUser({ [field]: value })
      await dispatch(fetchUserById(userData.user_id)).unwrap()
    } catch (err) {
      extractErrorDescription(err)
    }
  }

  return (
    <form className={styles.form}>
      <UserFormItem
        title="Display Name"
        value={userData?.username || ''}
        control={control}
        name="userName"
        placeholder="Enter name"
        onSubmit={(name) => {
          saveData(name, 'username')
        }}
      />
      <Webhooks />
      {/* <UserFormItem
        title="Email address"
        value={userData?.email || ''}
        control={control}
        name="email"
        placeholder="Enter email"
        onSubmit={(name) => {
          saveData(name, 'email')
        }}
      /> */}
    </form>
  )
}

export { Account }
