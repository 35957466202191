import { FC, useEffect, useState } from 'react'

import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import cn from 'classnames'

import { getTopTokensList } from '@/api/tokens'
import { TTopTokensListResponse } from '@/api/tokens/types'
import { RowLoadingPlaceholder } from '@/components/row-loading-placeholder'
import { RefreshButton } from '@/libs/common'
import { useAppSelector } from '@/store'

import { Row } from './libs/row/row'
import styles from './styles.module.scss'

type TProperty = {
  resolution: string
  dateFormat: string
}

const TrendingTable: FC<TProperty> = ({ resolution, dateFormat }) => {
  const [isLoading, setIsLoading] = useState(true)
  const currentCain = useAppSelector((state) => state.chain.currentChain)
  const [tokensLists, setTokenLists] = useState<TTopTokensListResponse | null>(null)

  const handleTopTokensList = async () => {
    try {
      setIsLoading(true)
      const { data } = await getTopTokensList(currentCain.id)
      setTokenLists(data?.data)
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    handleTopTokensList()
  }, [])
  return (
    <Paper className={styles.root}>
      <TableContainer className={cn(styles.container, styles.containerSmall)} id="holdings-table">
        <Table stickyHeader aria-label="sticky table" classes={styles}>
          <TableHead>
            <TableRow className={styles.headRow}>
              <TableCell className={cn(styles.tableHeadCell, styles.token)}>
                <div className={styles.refreshWrapper}>
                  Token
                  <RefreshButton
                    onClick={handleTopTokensList}
                    isLoading={isLoading}
                  ></RefreshButton>
                </div>
              </TableCell>
              <TableCell className={cn(styles.tableHeadCell, styles.exchanges)}>
                Exchanges
              </TableCell>
              <TableCell className={cn(styles.tableHeadCell, styles.price)}>Price</TableCell>
              <TableCell className={cn(styles.tableHeadCell, styles.time)}>1H</TableCell>
              <TableCell className={cn(styles.tableHeadCell, styles.time)}>4H</TableCell>
              <TableCell className={cn(styles.tableHeadCell, styles.time)}>12H</TableCell>
              <TableCell className={cn(styles.tableHeadCell, styles.time)}>24H</TableCell>
              <TableCell className={cn(styles.tableHeadCell, styles.vol)}>Vol / Liq</TableCell>
              <TableCell className={cn(styles.tableHeadCell, styles.vol)}>Txs / Mkt Cap</TableCell>
              <TableCell className={cn(styles.tableHeadCell, styles.vol)}>
                Buyers / Sellers
              </TableCell>
              <TableCell className={cn(styles.tableHeadCell, styles.vol)}>
                Created / Last Txn
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {tokensLists
              ? tokensLists[resolution as keyof TTopTokensListResponse]?.map((row: any) => (
                  <Row key={row.id} row={row} dateFormat={dateFormat} />
                ))
              : isLoading && <RowLoadingPlaceholder colSpan={11} />}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export { TrendingTable }
