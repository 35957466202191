import { FC } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import cls from 'classnames'

import { ButtonGroupRadio, ButtonGroupRadioButton } from '@/libs/common'
import { Typography } from '@/libs/common/typography'
import { DEFAULT_TOKEN } from '@/libs/configs/default-token.config.ts'
import { AppMode, AppRoute, LocalStorageItem } from '@/libs/enums'
import { useAppDispatch, useAppSelector } from '@/store'
import { setIsNavigateAvailable } from '@/store/slices/app.slice'

import styles from './styles.module.scss'

const SwitchVariant: FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const isNavigateAvailable = useAppSelector((state) => state.app.isNavigateAvailable)
  const { mode: currentAppMode } = useParams()
  const currentToken = useAppSelector((state) => state.chain.currentToken)

  const handleChangeVariant = (_: React.BaseSyntheticEvent, newValue: string) => {
    if (isNavigateAvailable && newValue !== currentAppMode) {
      //dispatch(setIsNavigateAvailable(false))
      localStorage.setItem(LocalStorageItem.MODE, newValue)
      localStorage.removeItem(LocalStorageItem.ACTIVE_PANEL_TAB)
      navigate(
        `${AppRoute.DASHBOARD}/${newValue}?token=${currentToken?.token.address ?? DEFAULT_TOKEN}`,
      )
    }
  }

  return (
    <ButtonGroupRadio
      onChange={handleChangeVariant}
      disabled={!isNavigateAvailable}
      value={currentAppMode}
      exclusive
      className={styles.group}
    >
      <ButtonGroupRadioButton value={AppMode.LITE}>
        <Typography
          variant="body2"
          textColor="color-grey-3"
          className={cls(currentAppMode === AppMode.LITE && styles.active)}
        >
          Lite
        </Typography>
      </ButtonGroupRadioButton>
      <ButtonGroupRadioButton value={AppMode.PRO}>
        <Typography
          variant="body2"
          textColor="color-grey-3"
          className={cls(currentAppMode === AppMode.PRO && styles.active)}
        >
          Pro
        </Typography>
      </ButtonGroupRadioButton>
    </ButtonGroupRadio>
  )
}

export { SwitchVariant }
