import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, TableCell, TableRow } from '@mui/material'
import cn from 'classnames'

import { TPanelHistoryOrder } from '@/api/favorites/types'
import rowStyles from '@/components/lite-history-table/row-styles.module.scss'
import styles from '@/components/lite-history-table/styles.module.scss'
import { handleROIColor } from '@/components/token-history-table/libs/helpers'
import { useAppMode } from '@/hooks/useAppMode'
import { Button, TokenPlaceholder, Typography } from '@/libs/common'
import { AppRoute, IconName } from '@/libs/enums'
import { formatNumber } from '@/libs/helper'
import { useAppSelector } from '@/store'

type TProps = {
  row: TPanelHistoryOrder
  index: number
}

const Row: FC<TProps> = ({ row, index }) => {
  // const navigate = useNavigate()
  // const profitPanel = useAppSelector((state) => state.profitSell.profitSell?.ap)
  // const { mode } = useAppMode()

  // const handleOpenShareProfitModal = () => {
  //   if (!profitPanel) return

  //   const tokenInfo = profitPanel.find((token: any) => token.id === row.id)
  //   navigate(`${AppRoute.DASHBOARD}/${mode}/${AppRoute.MODAL}/${AppRoute.SHARE_PROFIT}`, {
  //     state: { positionsSummary: tokenInfo ?? {} },
  //   })
  // }

  return (
    <TableRow
      className={cn(rowStyles.tableRow, {
        [rowStyles.backgroundEven]: index % 2 !== 0,
        [rowStyles.backgroundOdd]: index % 2 === 0,
      })}
    >
      <TableCell className={styles.cell}>
        <Box display="flex" gap={1} className={styles.token}>
          {row.i && row.i.s ? (
            <img className={styles.tokenLogo} src={row.i.s} alt={row.tn + ' token'} />
          ) : (
            <TokenPlaceholder />
          )}
          <div>
            <Typography variant="body2" textColor="color-white-3" className={styles.cellText}>
              {row.tos}
            </Typography>
          </div>
        </Box>
      </TableCell>
      <TableCell className={cn(styles.cell, styles.investedCell, styles.justifyRight)}>
        <div className={styles.currencyWrapper}>
          <Typography variant="body2" textColor="color-white-3" className={styles.cellText}>
            ${formatNumber(row.tc.u, 2).formatted}
          </Typography>
        </div>
      </TableCell>
      <TableCell className={cn(styles.cell, styles.justifyRight)}>
        <div className={styles.currencyWrapper}>
          <Typography variant="body2" textColor="color-white-3" className={styles.cellText}>
            ${formatNumber(row.ts.u).formatted}
          </Typography>
        </div>
      </TableCell>
      <TableCell
        className={cn(
          styles.cell,
          styles.tableRow,
          styles.colorGreen,
          styles.justifyRight,
          styles[handleROIColor(+row.trr.p)],
        )}
        align="right"
      >
        {row.trr && (
          <div>
            {row.trr.p === null ? (
              '-'
            ) : (
              <>
                {+row.trr.p >= 50 ? '+' : ''}
                {formatNumber(row.trr.p, 2).formatted}%
              </>
            )}
          </div>
        )}
      </TableCell>

      {/* <TableCell
        className={cn(styles.cell, styles.tableRow, styles.colorGreen, styles.justifyRight)}
        align="right"
      >
        <Button
          className={styles.shareBtn}
          styleVariant="borderless-gold"
          iconStartName={IconName.SHARE}
          transparent
          onClick={(e: any) => {
            e.stopPropagation()
            handleOpenShareProfitModal()
          }}
        >
          Share
        </Button>
      </TableCell> */}
    </TableRow>
  )
}

export { Row }
