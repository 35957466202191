import { FC, useEffect } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { Box } from '@mui/material'
import * as yup from 'yup'

import { Button, ButtonIcon, Icon, Input, Typography } from '@/libs/common'
import { IconName } from '@/libs/enums'

import styles from '../../styles.module.scss'
import { TAuthenticator } from '../types'

type TProps = {
  goBack: () => void
  authenticator: TAuthenticator
  onSubmit: () => void
}

const defaultValues = {
  masterPassword: '',
}

const ConfirmMasterPassword: FC<TProps> = ({ onSubmit, goBack, authenticator }) => {
  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    formState: { isValid, errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(
      yup.object({
        masterPassword: yup
          .string()
          .required('Master password is a required field')
          .max(32, 'Master password must contain maximum 32 characters'),
      }),
    ),
  })

  const masterPassword = watch('masterPassword')
  useEffect(() => {
    clearErrors()
  }, [masterPassword])

  const onFormSubmit: SubmitHandler<typeof defaultValues> = (values: typeof defaultValues) => {
    if (authenticator.master !== values.masterPassword) {
      setError('masterPassword', {
        message: 'Master password mismatches with generated one previously',
      })
      return
    }

    clearErrors()
    onSubmit()
  }

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} className={styles.content}>
      <div className={styles.header}>
        <ButtonIcon
          icon={IconName.ARROW_RIGHT}
          onClick={goBack}
          containerClassName={styles.backButton}
        />

        <Typography variant="h1" align="center" className={styles.title}>
          Confirm master password
        </Typography>
      </div>
      <div className={styles.confirmPasswordBody}>
        <Controller
          name="masterPassword"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <Input
              label="Master password"
              maxLength={32}
              isHideInputCaption
              className={styles.confirmInput}
              labelClassName={styles.confirmInputLabel}
              placeholder="Enter master password"
              {...field}
            />
          )}
        />

        {errors.masterPassword && (
          <Box className={styles.spendError} display="flex" gap={1}>
            <Icon name={IconName.INFO_ERROR} />
            <Typography variant="body1" textColor="red">
              {errors.masterPassword?.message}
            </Typography>
          </Box>
        )}
      </div>

      <Button disabled={!isValid} type="submit">
        Confirm
      </Button>
    </form>
  )
}

export { ConfirmMasterPassword }
