import { Dispatch, FC, MutableRefObject, SetStateAction, useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { Box, Divider } from '@mui/material'
import Grid from '@mui/material/Grid'
import cn from 'classnames'

import { ConnectWalletButton } from '@/components/header/libs/components/connect-wallet/connect-wallet-button.tsx'
import { useWindowDimensions } from '@/hooks/useWindowDimensions'
import {
  ButtonIcon,
  Icon,
  InputSearch,
  Popover,
  SignOutButton,
  Switch,
  TOnChangeVisible,
  Typography,
} from '@/libs/common'
import { Logo } from '@/libs/common/logo'
import { AppMode, AppRoute, IconName } from '@/libs/enums'
import { formatNumber } from '@/libs/helper'
// import { PlanName } from '@/libs/enums'
// import { TPlan } from '@/libs/types'
import { useAppDispatch, useAppSelector } from '@/store'
import { setConfirmationRequired } from '@/store/slices/user.slice.ts'

import { ChainStatusInfo } from './libs/components/chain-status-info'
import { InfoItem } from './libs/components/info-item'
import { SwitchVariant } from './libs/components/switch-variant'
import { UserBlock } from './libs/components/user-block'
import styles from './styles.module.scss'

const Header: FC<{ setCollapsed: Dispatch<SetStateAction<boolean>> }> = ({ setCollapsed }) => {
  const { mode } = useParams()
  // const [searchParams] = useSearchParams()
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const userData = useAppSelector((state) => state.user.userData)
  const userConfirmationRequired = useAppSelector((state) => state.user.userConfirmationRequired)
  const mainWallet = useAppSelector((state) => state.user.mainWallet)
  const ref: MutableRefObject<HTMLDivElement | null> = useRef(null)
  const [popoverState, setPopoverState] = useState<TOnChangeVisible>({
    visible: false,
    anchorE: undefined,
  })
  const navigate = useNavigate()
  const { width } = useWindowDimensions()
  const isMobileSidebar = width < 768
  const isTablet = width > 480 && width < 1200
  const dispatch = useAppDispatch()

  useEffect(() => {
    localStorage.removeItem('signData')
    localStorage.removeItem('account')
    // localStorage.removeItem('signUpData')
  }, [])

  const renderNavigation = () => (
    <nav className={styles.nav}>
      {/* <Link className={styles.navLink} to="/wallets">
        <div className={cn(styles.navLinkIcon, styles.navLinkIconWallet)}>
          <Icon name={IconName.WALLET} />
        </div>
        Wallets
      </Link>

      <Link className={styles.navLink} to="/settings">
        <div className={cn(styles.navLinkIcon, styles.navLinkIconSettings)}>
          <Icon name={IconName.SETTING} />
        </div>
        Settings
      </Link> */}

      <Link
        to={`${AppRoute.DASHBOARD}/${mode}/${AppRoute.MODAL}/${AppRoute.SELECT_WALLET_TO_DEPOSIT}`}
        className={styles.buttonLink}
      >
        <div className={styles.navLink}>
          <Icon name={IconName.DEPOSIT} />
          <span className={styles.buttonText}>Deposit</span>
        </div>
      </Link>
      <Link
        to={`${AppRoute.DASHBOARD}/${mode}/${AppRoute.MODAL}/${AppRoute.TRANSFER}/${mainWallet?.address}`}
        className={styles.buttonLink}
      >
        <div className={styles.navLink}>
          <Icon name={IconName.TRANSFER} />
          <span className={styles.buttonText}>Transfer</span>
        </div>
      </Link>
      <Link
        to={`${AppRoute.DASHBOARD}/${mode}/${AppRoute.MODAL}/${AppRoute.AFFILIATES}`}
        className={styles.buttonLink}
      >
        <div className={styles.navLink}>
          <Icon name={IconName.AFFILIATE} />
          <span className={styles.buttonText}>Affiliates</span>
        </div>
      </Link>
      <Link
        to={`${AppRoute.DASHBOARD}/${mode}/${AppRoute.MODAL}/${AppRoute.WALLET_MANAGEMENT}`}
        className={styles.buttonLink}
      >
        <div className={styles.navLink}>
          <Icon name={IconName.GOLD_WALLET} />
          <span className={styles.buttonText}>Wallets</span>
        </div>
      </Link>
      <Divider variant="fullWidth" className={styles.divider} />
      <Grid
        justifyContent="space-between"
        alignItems="center"
        display="flex"
        onClick={() => {
          setPopoverState({
            visible: false,
            anchorE: undefined,
          })
          navigate(`${AppRoute.DASHBOARD}/${mode}/${AppRoute.MODAL}/${AppRoute.ACCOUNT_LOCKING}`)
        }}
      >
        <div className={cn(styles.navLink)}>
          <div className={cn(styles.navLinkIcon)}>
            <Icon name={IconName.LOCK} />
          </div>
          {userData?.locked ? 'Unlock' : 'Lock'} account
        </div>

        <Switch value={userData?.locked || false} checked={userData?.locked || false} />
      </Grid>
      <Grid
        justifyContent="space-between"
        alignItems="center"
        display="flex"
        onClick={() => {
          dispatch(setConfirmationRequired(!userConfirmationRequired))
        }}
      >
        <div className={cn(styles.navLink)}>
          <div className={cn(styles.navLinkIcon)}>
            <Icon name={IconName.APPROVE} />
          </div>
          Confirmation on sell account
        </div>

        <Switch
          value={userConfirmationRequired || false}
          checked={userConfirmationRequired || false}
        />
      </Grid>
      <div className={styles.navLink}>
        <SignOutButton variant="onboarding" buttonClassName={styles.signOutButton} />
      </div>
    </nav>
  )

  return (
    <>
      <Grid
        ref={ref}
        container
        component="header"
        alignItems="center"
        alignContent="center"
        justifyContent="center"
        className={cn(styles.header, styles[currentChain.chainName])}
      >
        <Grid
          container
          alignItems="center"
          alignContent="center"
          justifyContent="space-between"
          className={styles.container}
        >
          <div className={cn(styles.logoWrapper, mode ? styles[mode] : '')}>
            {mode == AppMode.LITE && <Logo size="medium" hideText={isMobileSidebar} />}
            {mode == AppMode.PRO && (
              <>
                {width < 1200 && (
                  <Box
                    display="flex"
                    gap={3}
                    alignItems="center"
                    className={styles.logoMenuWrapper}
                  >
                    <ButtonIcon
                      icon={IconName.SIDEBAR_COLLAPSED_BUTTON}
                      className={cn(styles.controlMobile, { [styles.open]: true })}
                      onClick={() => setCollapsed((state) => !state)}
                    />

                    {isTablet && <Logo size="medium" hideText={isMobileSidebar} />}
                  </Box>
                )}

                {!isTablet && <Logo size="medium" hideText={isMobileSidebar} />}
              </>
            )}

            <div className={styles.search}>
              <SwitchVariant />

              {width >= 1200 && (
                <>
                  <div className={styles.searchBlock}>
                    <InputSearch placeholder="Enter token name or address" />
                  </div>
                  <ChainStatusInfo />
                </>
              )}
            </div>

            {width < 1200 && <ChainStatusInfo />}

            {userData ? (
              <div
                className={styles.userData}
                onClick={(e) => {
                  setPopoverState((prevValue) => ({
                    visible: !prevValue.visible,
                    anchorE: e.currentTarget,
                  }))
                }}
              >
                <div>
                  <UserBlock userName={userData?.username} />

                  <InfoItem
                    title="Balance:"
                    value={
                      <Typography
                        variant="caption"
                        textColor="light-grey"
                        className={styles.balance}
                        title={
                          mainWallet?.balanceFormatted &&
                          formatNumber(mainWallet?.balanceFormatted).formatted.toString()
                        }
                      >
                        <Icon name={IconName.ETH_CURRENCY} />{' '}
                        {mainWallet?.balanceFormatted
                          ? formatNumber(mainWallet?.balanceFormatted).formatted
                          : 0}
                      </Typography>
                    }
                  />
                </div>

                <div className={styles.switch}>
                  <Icon name={IconName.SWITCH} />
                </div>
              </div>
            ) : (
              <ConnectWalletButton />
            )}

            {!isMobileSidebar && (
              <Popover
                className={styles.popover}
                popoverState={popoverState}
                onChangeVisible={setPopoverState}
              >
                {renderNavigation()}
              </Popover>
            )}
          </div>
        </Grid>

        {isMobileSidebar && (
          <div
            className={cn(styles.mobileSidebar, {
              [styles.visible]: popoverState.visible,
            })}
          >
            <Grid rowGap={2} display="flex" flexDirection="column">
              <ChainStatusInfo className={styles.mobileHidden} />
              <SwitchVariant />
              {renderNavigation()}
            </Grid>
          </div>
        )}
      </Grid>

      {isMobileSidebar && (
        <div
          className={cn(styles.overlay, {
            [styles.visible]: popoverState.visible,
          })}
          onClick={(e) => {
            e.stopPropagation()
            setPopoverState({
              visible: false,
              anchorE: undefined,
            })
          }}
        />
      )}
    </>
  )
}

export { Header }
