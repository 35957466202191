import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getWebHooks } from '@/api/device'
import { changeUserBuyTemplatesOrder, getUserBuyTemplates } from '@/api/templates'
import { getSetup, getUserById } from '@/api/user'
import { deleteWallets, getUserWalletsFormatted } from '@/api/wallet'
import { formatBalanceForHoldings, getHoldingsSettings } from '@/libs/helper'
import { THoldingsSettings, TUser } from '@/libs/types'
import { TSniperHoldings } from '@/libs/types/sniper-holdings-socket-response.type'
import { TSniperSocketBalance } from '@/libs/types/sniper-socket-responses.type'
import { TBuyTemplate } from '@/libs/types/template'
import {
  TAuthenticatorData,
  TDefaultBuySellPriorities,
  TQuickBuySettings,
  TUserCurrentSelectedTemplate,
  TUserWallet,
  TUserWalletUnconfirmed,
} from '@/libs/types/user.type'
import { TWebhook } from '@/libs/types/webhooks'
import { TRootState } from '@/store'
import { createAsyncThunkWithControll } from '@/utils/requestsController'

type TInitialState = {
  userData: TUser | null
  userWallets: TUserWallet[] | null
  userSkipDeleteUnconfirmedWallets: boolean
  userConfirmationRequired: boolean
  userWalletsUnconfirmed: TUserWalletUnconfirmed[] | null
  userHoldingsWebsocket: TSniperHoldings | null
  userHoldingsLoading: boolean
  userHoldingsSettings: THoldingsSettings
  mainWallet: TUserWallet | null
  userTemplates: {
    buyTemplates: TBuyTemplate[] | null
  }
  userWebHooks: TWebhook[]
  defaultPriorities: TDefaultBuySellPriorities
  quickBuySettings: TQuickBuySettings
  userCurrentSelectedTemplate: TUserCurrentSelectedTemplate
  userAuthenticatorData: TAuthenticatorData
}

const initialState: TInitialState = {
  userData: null,
  userWallets: null,
  userSkipDeleteUnconfirmedWallets: false,
  userConfirmationRequired: localStorage?.userConfirmationRequired || false,
  userWalletsUnconfirmed: null,
  userHoldingsWebsocket: null,
  userHoldingsLoading: true,
  userHoldingsSettings: getHoldingsSettings(),
  mainWallet: null,
  userTemplates: {
    buyTemplates: null,
  },
  userWebHooks: [],
  defaultPriorities: {
    buy_priority: '1',
    approve_priority: '1',
    sell_priority: '1',
  },
  userCurrentSelectedTemplate: {
    group: null,
    id: null,
  },
  userAuthenticatorData: {
    s2fa: null,
  },
  quickBuySettings: {
    isInitialized: null,
    settings: {
      spend: '0.00005',
      templateId: null,
    },
  },
}

const fetchUserWalletsById = createAsyncThunk(
  'user/fetchUserWalletsById',
  async (userId: string | null, { getState }) => {
    if (!userId) userId = (getState() as TRootState).user.userData?.user_id || ''
    return await getUserWalletsFormatted(userId)
  },
)

const fetchUserById = createAsyncThunk(
  'user/fetchUserById',
  async (userId: string, { dispatch }) => {
    const { data } = await getUserById(userId)
    await dispatch(fetchPriorities(userId))
    return data.data
  },
)
const fetchUserWebHooks = createAsyncThunk('user/fetchUserWebHooks', async () => {
  const { data } = await getWebHooks()
  return data.data
})

// Old thunks

const fetchUserBuyTemplates = createAsyncThunk('user/fetchUserBuyTemplates', async () => {
  const { data } = await getUserBuyTemplates()
  return data.data.sort((a, b) => a.order - b.order)
})
const fetchPriorities = createAsyncThunk(
  'user/fetchPriorities',
  async (userId: string | null, { dispatch, getState }) => {
    if (!userId) userId = (getState() as TRootState).user.userData?.user_id || ''
    const { data } = await getSetup(userId)
    const { buy_priority, sell_priority, approve_priority } = data.data
    dispatch(
      setUserPrioritySettings({
        buy_priority: buy_priority ? `${buy_priority}` : '1',
        sell_priority: sell_priority ? `${data.data.sell_priority}` : '1',
        approve_priority: approve_priority ? `${data.data.approve_priority}` : '1',
      }),
    )
  },
)

const fetchDeleteUserUnconfirmedWallets = createAsyncThunk(
  'user/fetchDeleteUserUnconfirmedWallets',
  async (_, { getState }) => {
    const { userData, userWalletsUnconfirmed, userSkipDeleteUnconfirmedWallets } = (
      getState() as TRootState
    ).user

    if (userData && userWalletsUnconfirmed && !userSkipDeleteUnconfirmedWallets) {
      await deleteWallets(userWalletsUnconfirmed.map(({ address }) => address))
    }
  },
)

const {
  thunk: fetchUserWallets,
  thunkForce: fetchUserWalletsForce,
  applyExtraReducers: applyExtraReducersForUserWallets,
} = createAsyncThunkWithControll(
  'user/fetchUserWallets',
  async (userId: string, { dispatch, getState }) => {
    const idToUse = userId || (getState() as TRootState).user.userData?.user_id
    if (!idToUse) return null
    await dispatch(fetchDeleteUserUnconfirmedWallets()).unwrap()
    return dispatch(fetchUserWalletsById(idToUse)).unwrap()
  },
)

const updateBuyTemplatesOrder = createAsyncThunk(
  'user/updateBuyTemplatesOrder',
  async (updatedTemplateOrder: { id: string; order: number }[]) => {
    const { data } = await changeUserBuyTemplatesOrder(updatedTemplateOrder)
    return data.sort((a, b) => a.order - b.order)
  },
)

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, { payload }: PayloadAction<TUser>) => {
      state.userData = payload
    },
    setUserWallets: (state, { payload }: PayloadAction<TUserWallet[]>) => {
      const main = payload.find((wallet: TUserWallet) => wallet.is_default)
      if (main) state.mainWallet = main
      state.userWallets = payload
    },
    setUserWalletsUnconfirmed: (state, { payload }: PayloadAction<TUserWalletUnconfirmed[]>) => {
      state.userWalletsUnconfirmed = payload.map(({ public_key }) => {
        return { public_key }
      })
      state.userSkipDeleteUnconfirmedWallets = true
    },
    setUserPrioritySettings: (state, { payload }: PayloadAction<TDefaultBuySellPriorities>) => {
      state.defaultPriorities = payload
    },
    setQuickBuySettings: (state, { payload }: PayloadAction<Partial<TQuickBuySettings>>) => {
      state.quickBuySettings = { ...state.quickBuySettings, ...payload }
    },
    updateUserWallet: (state, { payload }: PayloadAction<TSniperSocketBalance[]>) => {
      if (!state.userWallets) return

      const updatedData: TUserWallet[] = []

      payload.forEach((balance) => {
        const wallet = state.userWallets?.find((el) => el.address === balance.a)

        if (wallet)
          updatedData.push({ ...wallet, balanceFormatted: balance.ba.f, balanceUsd: balance.ba.u })
      })

      if (!updatedData.length) return

      const mainWalletUpdated = updatedData.find(
        (item) => item.address === state.mainWallet?.address,
      )
      if (mainWalletUpdated) {
        state.mainWallet = mainWalletUpdated
      }

      state.userWallets = state.userWallets.map((el) => {
        const updatedWallet = updatedData.find((updatedEl) => updatedEl.address === el.address)
        return updatedWallet ? updatedWallet : el
      })
    },
    setUserBuyTemplates: (state, { payload }: PayloadAction<TBuyTemplate[]>) => {
      state.userTemplates.buyTemplates = payload
    },
    setUserHoldingsWebsocket: (state, { payload }: PayloadAction<TSniperHoldings>) => {
      const parsed = JSON.parse(JSON.stringify(payload)) as TSniperHoldings
      payload.v.t = formatBalanceForHoldings(parsed.v.t)
      payload.s = formatBalanceForHoldings(parsed.s)
      state.userHoldingsWebsocket = payload
      state.userHoldingsLoading = false
    },
    setUserAuthenticatorData: (state, { payload }: PayloadAction<TAuthenticatorData>) => {
      state.userAuthenticatorData = payload
    },
    setUserHoldingsSettings: (state, { payload }: PayloadAction<THoldingsSettings>) => {
      state.userHoldingsSettings = payload
    },
    removeUser: (state) => {
      state.userData = null
      state.userWallets = null
    },
    clearUserWalletsUnconfirmed: (state) => {
      state.userWalletsUnconfirmed = null
    },
    toggleSkipCleanUnconfirmedWallets: (state, { payload }: PayloadAction<boolean>) => {
      state.userSkipDeleteUnconfirmedWallets = payload
    },
    setCurrentSelectedTemplate: (
      state,
      { payload }: PayloadAction<TUserCurrentSelectedTemplate>,
    ) => {
      state.userCurrentSelectedTemplate = payload
    },
    clearCurrentSelectedTemplate: (state) => {
      state.userCurrentSelectedTemplate = { id: null, group: null }
    },
    setConfirmationRequired: (state, { payload }) => {
      localStorage.userConfirmationRequired = payload
      state.userConfirmationRequired = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserWalletsById.fulfilled, (state, { payload }) => {
      const main = payload.find((wallet: TUserWallet) => wallet.is_default)
      if (main) state.mainWallet = main
      state.userWallets = payload.map((wallet: TUserWallet) => {
        const currentWallet = state.userWallets?.find(
          (selfWallet) => selfWallet.address === wallet.address,
        )

        return {
          ...currentWallet,
          ...wallet,
        }
      })
    })

    builder.addCase(fetchUserById.fulfilled, (state, { payload }) => {
      state.userData = payload
    })

    builder.addCase(fetchDeleteUserUnconfirmedWallets.fulfilled, (state) => {
      state.userWalletsUnconfirmed = null
      state.userSkipDeleteUnconfirmedWallets = true
    })

    applyExtraReducersForUserWallets(builder, {
      onFulfilled: (state, { payload }) => {
        const main = payload.find((wallet: TUserWallet) => wallet.is_default)
        if (main) state.mainWallet = main
        state.userWallets = payload
      },
    })

    builder.addCase(fetchUserBuyTemplates.fulfilled, (state, { payload }) => {
      state.userTemplates.buyTemplates = payload
    })

    builder.addCase(updateBuyTemplatesOrder.fulfilled, (state, { payload }) => {
      state.userTemplates.buyTemplates = payload
    })

    builder.addCase(fetchUserWebHooks.fulfilled, (state, { payload }) => {
      state.userWebHooks = payload
    })
  },
})

const {
  setUser,
  setUserWallets,
  setUserWalletsUnconfirmed,
  setUserHoldingsWebsocket,
  removeUser,
  updateUserWallet,
  clearUserWalletsUnconfirmed,
  toggleSkipCleanUnconfirmedWallets,
  setCurrentSelectedTemplate,
  setUserBuyTemplates,
  clearCurrentSelectedTemplate,
  setUserHoldingsSettings,
  setQuickBuySettings,
  setUserPrioritySettings,
  setUserAuthenticatorData,
  setConfirmationRequired,
} = userSlice.actions

export {
  userSlice,
  // Reducers
  setUser,
  setUserWallets,
  setUserWalletsUnconfirmed,
  removeUser,
  clearUserWalletsUnconfirmed,
  toggleSkipCleanUnconfirmedWallets,
  setCurrentSelectedTemplate,
  setUserBuyTemplates,
  clearCurrentSelectedTemplate,
  setConfirmationRequired,
  setUserHoldingsWebsocket,
  setUserHoldingsSettings,
  updateUserWallet,
  setUserPrioritySettings,
  setUserAuthenticatorData,
  setQuickBuySettings,
  // Thunks
  fetchUserWallets,
  fetchUserById,
  fetchUserWalletsForce,
  fetchUserBuyTemplates,
  fetchDeleteUserUnconfirmedWallets,
  updateBuyTemplatesOrder,
  fetchUserWalletsById,
  fetchPriorities,
  fetchUserWebHooks,
}
