import { FC } from 'react'
import { Link } from 'react-router-dom'

import { Divider } from '@mui/material'
import cls from 'classnames'

import styles from '@/components/selected-token-info/styles.module.scss'
import { useWindowDimensions } from '@/hooks/useWindowDimensions'
import { ButtonIcon, Icon, Typography } from '@/libs/common'
import { TokenDataItem } from '@/libs/common/token-data-item'
import { IconName } from '@/libs/enums'
import {
  checkSimulationValue,
  countDays,
  formatPairSymbol,
  getClogColor,
  getPercentage,
  getTaxesColor,
} from '@/libs/helper'
import { formatTokenPrice } from '@/libs/helper/formatTokenPrice'
import { TSniperSimulationData } from '@/libs/types/sniper-simulation-socket-response.type'
import { TTokenInfo } from '@/libs/types/token-info-response.type'

type TProps = {
  selectedToken: TTokenInfo
  simulation: TSniperSimulationData | null
  handleOpenModal: () => void
}

const TokenInfoPro: FC<TProps> = ({ simulation, selectedToken, handleOpenModal }) => {
  const windowDimension = useWindowDimensions()
  const isResponsive = windowDimension.width <= 1600
  const isMobile = windowDimension.width <= 1300
  const isDataAvailable = !!simulation

  return (
    <>
      <Divider orientation="vertical" flexItem className={styles.divider} />
      <TokenDataItem
        title="Price"
        value={
          <div className={styles.primaryText}>
            ${simulation && formatTokenPrice(simulation.p.ou).formatted}
          </div>
        }
        isDataAvailable={isDataAvailable}
      />

      <Divider orientation="vertical" flexItem className={styles.divider} />

      <TokenDataItem
        title="Marketcap"
        value={simulation && `$${formatTokenPrice(simulation.t.mc).raw}`}
        isGreen
        isDataAvailable={isDataAvailable}
      />

      <Divider orientation="vertical" flexItem className={styles.divider} />

      {!isMobile && (
        <>
          <TokenDataItem
            title="Gain"
            tooltipInfo="How much the token has grown from the first candle to the current moment."
            value={`${simulation?.t.g || 0}x`}
            isGreen
            isDataAvailable={isDataAvailable}
          />

          <Divider orientation="vertical" flexItem className={styles.divider} />

          <TokenDataItem
            title="Liquidity"
            tooltipInfo="The volume of ETH in Liquidity Pool. Other services may show doubled volume as they include tokenized half of LP."
            value={
              simulation && (
                <Typography variant="body2" className={styles.defaultColor}>
                  <div>${formatTokenPrice(simulation.l.p.vu).formatted}</div>
                  <div className={styles.additionalInfo}>
                    {checkSimulationValue(simulation.l.p.r) ? (
                      <>
                        {formatTokenPrice(simulation.l.p.r).formatted}{' '}
                        {formatPairSymbol(simulation.l.p.a, selectedToken.pair)}
                      </>
                    ) : (
                      '-'
                    )}
                  </div>
                </Typography>
              )
            }
            isDataAvailable={isDataAvailable}
            isCentered
          />

          <Divider orientation="vertical" flexItem className={styles.divider} />

          <TokenDataItem
            title="Trade Open"
            tooltipInfo="The token is tradeable. Sometimes, token trading may be disabled until the developer calls the appropriate function."
            value={simulation?.t.t ? 'Yes' : 'No'}
            isDataAvailable={isDataAvailable}
            className={simulation?.t.t ? styles.greenText : styles.redText}
            isCentered
          />

          <Divider orientation="vertical" flexItem className={styles.divider} />
        </>
      )}

      {!isResponsive && (
        <>
          <TokenDataItem
            title="Honeypot"
            tooltipInfo="If the token is a Honeypot, purchased tokens will not be able to be sold."
            value={simulation?.t.h ? 'Yes' : 'No'}
            isDataAvailable={isDataAvailable}
            isCentered
            className={!simulation?.t.h ? styles.greenText : styles.redText}
          />

          <Divider orientation="vertical" flexItem className={styles.divider} />

          <TokenDataItem
            title="Renounced"
            tooltipInfo="When a smart contract is renounced, the developer can't call functions on it. It's safe when it's renounced."
            value={simulation?.t.r ? 'Yes' : 'No'}
            isDataAvailable={isDataAvailable}
            isCentered
            className={simulation?.t.r ? styles.greenText : styles.redText}
          />
          <Divider orientation="vertical" flexItem className={styles.divider} />

          <TokenDataItem
            title="Tax Buy / Sell / Transfer"
            tooltipInfo="The amount of tax set by the developer. Means how many tokens from your transaction will be transferred to the developer when buying / selling / transferring."
            value={
              simulation && (
                <>
                  <span className={styles[getTaxesColor(simulation.tx.b)]}>
                    {getPercentage(simulation.tx.b)}
                  </span>{' '}
                  /{' '}
                  <span className={styles[getTaxesColor(simulation.tx.s)]}>
                    {getPercentage(simulation.tx.s)}
                  </span>{' '}
                  /{' '}
                  <span className={styles[getTaxesColor(simulation.tx.t)]}>
                    {getPercentage(simulation.tx.t)}
                  </span>
                </>
              )
            }
            isDataAvailable={isDataAvailable}
            noDataValue="- / - / -"
            isCentered
          />

          <Divider orientation="vertical" flexItem className={styles.divider} />

          <TokenDataItem
            title="Burn LP"
            tooltipInfo="Indicates how much of the liquidity pool has been burned. The developer has no control over the burned pool. The best value is 100%."
            value={simulation?.l.b === '-' ? simulation?.l.b : `${simulation?.l.b}%`}
            isDataAvailable={isDataAvailable}
            isCentered
            className={simulation?.l.b && +simulation?.l.b > 50 ? styles.greenText : ''}
          />

          <Divider orientation="vertical" flexItem className={styles.divider} />

          <TokenDataItem
            title="Token Locked"
            tooltipInfo="The duration of the token's liquidity pool is locked or unlocked."
            isDataAvailable={isDataAvailable}
            isCentered
            value={
              <Typography
                variant="body2"
                className={cls(
                  simulation?.additional_data?.is_pair_locked.status
                    ? styles.greenText
                    : styles.grayText,
                )}
              >
                {simulation?.additional_data?.is_pair_locked.status ? 'Yes' : 'No'}{' '}
                <span>
                  {!!simulation?.additional_data?.is_pair_locked.status &&
                    simulation?.additional_data?.is_pair_locked.unlock_date &&
                    `(${countDays(simulation?.additional_data?.is_pair_locked.unlock_date)}d)`}
                </span>
                <div className={styles.additionalInfo}>
                  {simulation?.additional_data?.is_pair_locked.locked_percentage || ''}
                </div>
              </Typography>
            }
          />

          <Divider orientation="vertical" flexItem className={styles.divider} />

          <TokenDataItem
            title="Clog"
            tooltipInfo="How many tokens are stored in the smart contract waiting to be sold. The best value is 0%."
            value={`${simulation?.t.c}%`}
            isDataAvailable={isDataAvailable}
            className={styles[getClogColor(+(simulation?.t.c || '0'))]}
            isCentered
          />

          <Divider orientation="vertical" flexItem className={styles.divider} />

          <TokenDataItem
            title="Max Buy / Wallet"
            tooltipInfo="How many tokens can be purchased per transaction / how many tokens can be held per wallet."
            value={
              simulation && (
                <Typography variant="body2" className={styles.defaultColor}>
                  <div>
                    {`${getPercentage(simulation.lt.b.p)} /
            ${getPercentage(simulation.lt.w.p || '0')}`}
                  </div>
                  <div className={styles.additionalInfo}>
                    {checkSimulationValue(simulation.lt.b.ccc)
                      ? formatTokenPrice(simulation.lt.b.ccc).formatted
                      : '-'}{' '}
                    /{' '}
                    {checkSimulationValue(simulation.lt.w.ccc)
                      ? formatTokenPrice(simulation.lt.w.ccc || '0').formatted
                      : '-'}
                  </div>
                </Typography>
              )
            }
            isDataAvailable={isDataAvailable}
            noDataValue="- / -"
            isCentered
          />

          <Divider orientation="vertical" flexItem className={styles.divider} />
        </>
      )}

      <div className={styles.tokenDataWrapper}>
        <ButtonIcon tooltipTitle="List" onClick={handleOpenModal}>
          <Icon name={IconName.LIST_ALT} />
        </ButtonIcon>

        <Link
          to={`https://etherscan.io/token/${selectedToken.token.address}`}
          target="_blank"
          className={styles.link}
        >
          <Icon name={IconName.ETHERSCAN_GOLD} />
        </Link>
      </div>
    </>
  )
}

export { TokenInfoPro }
