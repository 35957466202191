import { api } from '@/api'
import { getCurrentUserId } from '@/libs/helper/getCurrentUserId.ts'

const BASE_URL = 'api/v1/device'

const getDeviceCodeInfo = (code: string) => api.get(`${BASE_URL}/authentication/${code}/get?l=en`)
const getDeviceCodeStatus = (code: string) =>
  api.get(`${BASE_URL}/authentication/${code}/status?l=en`)

const updateDeviceCodeStatus = (data: object) => {
  const userId = getCurrentUserId()
  return api.post(`${BASE_URL}/authentication/${userId}/update?l=en`, data)
}

const createWebHook = (data: object) => {
  const userId = getCurrentUserId()
  return api.post(`${BASE_URL}/webhook/${userId}/create?l=en`, data)
}

const updateWebHook = (data: object) => {
  const userId = getCurrentUserId()
  return api.post(`${BASE_URL}/webhook/${userId}/update?l=en`, data)
}

const deleteWebHook = (id: string) => {
  const userId = getCurrentUserId()
  return api.post(`${BASE_URL}/webhook/${userId}/delete?l=en`, {
    id: id,
    blockchain: 1,
  })
}

const getWebHooks = () => {
  const userId = getCurrentUserId()
  return api.get(`${BASE_URL}/webhook/${userId}/get?l=en&b=1`)
}
export {
  getDeviceCodeInfo,
  getDeviceCodeStatus,
  updateDeviceCodeStatus,
  createWebHook,
  getWebHooks,
  deleteWebHook,
  updateWebHook,
}
