import { FC, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'

import { getDeviceCodeInfo, updateDeviceCodeStatus } from '@/api/device'
import { AuthCard } from '@/components/auth-card'
import { CustomToast } from '@/components/custom-toast'
import useCheckUser from '@/hooks/useCheckUser.ts'
import { useSelectNewToken } from '@/hooks/useSelectNewToken.hook'
import { Button, Icon, Typography } from '@/libs/common'
import { DEFAULT_TOKEN } from '@/libs/configs/default-token.config.ts'
import { AppMode, AppRoute, IconName } from '@/libs/enums'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

type TProps = {
  device: {
    id: string
    name: string
    type: string
  }
}

const AddDevice = () => {
  const navigate = useNavigate()
  const [confirmed, setConfirmation] = useState(false)
  const [device, setDeviceInfo] = useState<any>(null)
  const [searchParams] = useSearchParams()
  const checkUserAndExecute = useCheckUser()
  const userData = useAppSelector((state) => state.user.userData)

  const handleNewTokenSelection = useSelectNewToken()

  useEffect(() => {
    localStorage.code = searchParams.get('code')
    console.log('ENTRY')

    checkUserAndExecute(async () => {
      console.log('USER EXIST')

      const code = localStorage.code
      try {
        console.log('GETTING CODE')
        const { data } = await getDeviceCodeInfo(code as string)
        setDeviceInfo(data.data)
        console.log('SET DATA FROM CODE')
      } catch (e) {
        console.log('ERROR')

        CustomToast('error', `Invalid code`)
        await handleNewTokenSelection(DEFAULT_TOKEN)
      } finally {
        localStorage.removeItem('code')
      }
    })
  }, [userData])

  const addDevice = async () => {
    try {
      await updateDeviceCodeStatus({
        id: searchParams.get('code'),
        status: 'approved',
      })
      setConfirmation(true)
    } catch (e) {
      CustomToast('error', `Something went wrong!`)
    }
  }

  return (
    <>
      {confirmed ? (
        <AuthCard className={styles.card} isHeaderButtons>
          <div className={styles.content}>
            <Stack spacing={1} useFlexGap>
              <Grid container justifyContent="center">
                <Icon name={IconName.SUCCESS} />
              </Grid>
              <Typography variant="h1" align="center">
                Success
              </Typography>
              <Typography variant="body1" align="center">
                Your have successfully linked the following device:{' '}
              </Typography>
            </Stack>
            <DeviceInfo device={device} />

            <Button
              onClick={() =>
                navigate(`${AppRoute.DASHBOARD}/${AppMode.PRO}?token=${DEFAULT_TOKEN}`)
              }
            >
              Continue to dashboard
            </Button>
          </div>
        </AuthCard>
      ) : (
        <AuthCard
          className={styles.card}
          isHeaderButtons
          footerLink={{
            title: 'Go to dashboard',
            link: `${AppRoute.DASHBOARD}/${AppMode.PRO}?token=${DEFAULT_TOKEN}`,
          }}
        >
          <div className={styles.content}>
            <div className={styles.titleWrapper}>
              <Typography variant="h1" className={styles.title} align="center">
                Please confirm, that you <br /> want to attach this device
              </Typography>
            </div>
            <DeviceInfo device={device} />

            <Button onClick={addDevice}>Confirm</Button>
            <Button
              styleVariant="black"
              onClick={() =>
                navigate(`${AppRoute.DASHBOARD}/${AppMode.PRO}?token=${DEFAULT_TOKEN}`)
              }
            >
              Deny
            </Button>
          </div>
        </AuthCard>
      )}
    </>
  )
}

const DeviceInfo: FC<TProps> = ({ device }) => {
  return (
    <div className={styles.deviceContainer}>
      <div className={styles.deviceCol}>
        <div className={styles.deviceTitle}>Device Type</div>
        <div className={styles.deviceValue}>{device?.type}</div>
      </div>
      <div className={styles.deviceCol}>
        <div className={styles.deviceTitle}>Device Name</div>
        <div className={styles.deviceValue}>{device?.name}</div>
      </div>
      <div className={styles.deviceCol}>
        <div className={styles.deviceTitle}>Device ID</div>
        <div className={styles.deviceValue}>{device?.id}</div>
      </div>
    </div>
  )
}
export { DeviceInfo, AddDevice }
