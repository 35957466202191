const enum LocalStorageItem {
  TOKEN_ADDRESS = 'initTokenAddress',
  HOLDINGS_SETTINGS = 'holdingsSettings',
  LITE_BUY_AMOUNT = 'liteBuyAmount',
  PRIORITIES = 'priorities',
  MODE = 'mode',
  ACTIVE_TAB = 'activeTab',
  ACTIVE_PANEL_TAB = 'activePanelTab',
  ACTIVE_TEMPLATE_INDEX = 'activeTemplateIndex',
  QUICK_BUY_SETTINGS = 'quickBuySettings',
}

export { LocalStorageItem }
