import { FC, useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import {
  WagmiProvider,
  createConfig,
  http,
  useAccount,
  useBalance,
  useChainId,
  useConnect,
  useDisconnect,
  useSignMessage,
} from 'wagmi'
import { mainnet } from 'wagmi/chains'
import { walletConnect } from 'wagmi/connectors'

import { getNonce, getStateOfUser, signIn } from '@/api/user'
import { TSignupBody } from '@/api/user/types'
import { CustomToast } from '@/components/custom-toast'
import { ListButton, Typography } from '@/libs/common'
import { AppMode, AppRoute, TabName } from '@/libs/enums'
import { createQueryString } from '@/libs/helper/createQueryString'
import { useAppDispatch } from '@/store'
import { fetchUserBuyTemplates, fetchUserById } from '@/store/slices/user.slice.ts'

import styles from './styles.module.scss'

// 0. Setup queryClient
const queryClient = new QueryClient()
// 1. Your WalletConnect Cloud project ID
const projectId = import.meta.env.VITE_WALLET_CONNECT_PROJECT_ID

const chains = [mainnet] as const

const wagmiConfig = createConfig({
  chains,
  transports: {
    [mainnet.id]: http(),
  },
  connectors: [walletConnect({ projectId })],
})

type TProps = {
  isAuth?: boolean
}
let nonce = ''

const ConnectWallet: FC<TProps> = ({ isAuth }) => {
  const { disconnect } = useDisconnect()
  const { address, isConnected } = useAccount()
  const chainId = useChainId()
  const { connect } = useConnect()
  const { data: signMessageData, signMessage } = useSignMessage()
  const providers = wagmiConfig.connectors.filter((wallet) => {
    return wallet.id === 'io.metamask' || wallet.id === 'walletConnect' || wallet.id === 'io.rabby'
  })

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { mode } = useParams()
  const [searchParams] = useSearchParams()

  const { isLoading } = useBalance({
    address: address,
  })

  async function redirect() {
    if (localStorage.signData) {
      const data = JSON.parse(localStorage.signData)
      if (address === data.signatory) {
        CustomToast('error', 'Please select your previous wallet, not the same one')
        disconnect()
        setTimeout(() => {
          location.reload()
        }, 3000)
        return
      }
    }

    if (address) {
      try {
        await signIn({
          blockchain: 1,
          signatory: address,
          signature: signMessageData,
          nonce: nonce,
        }).then(async (res) => {
          localStorage.token = res.data.data.token
          localStorage.refreshToken = res.data.data.refresh_token
          await dispatch(fetchUserById(res.data.data.id)).unwrap()
          dispatch(fetchUserBuyTemplates())
          if (!isAuth) {
            navigate(
              localStorage.code
                ? `${AppRoute.ADD_DEVICE}?code=${localStorage.code}`
                : {
                    pathname: `${AppRoute.DASHBOARD}/${mode || AppMode.PRO}`,
                    search: createQueryString({
                      token: searchParams.get('token'),
                      [TabName.DASHBOARD_NAV]: 'dashboard',
                    }),
                  },
            )
          } else {
            navigate(`/${AppRoute.ADD_CHAIN}?wallet=${address}`)
          }
        })
      } catch (e: any) {
        const errCode = e?.response?.data?.data?.code as string
        if (errCode === 'Q9S7WRQT81') {
          saveData()
          if (isAuth) {
            navigate(`/${AppRoute.ENTER_CODE}?wallet=${address}`)
          } else {
            navigate(`/${AppRoute.ENTER_CODE}`)
          }
        } else {
          navigate(-1)
        }
      }
    }
  }

  useEffect(() => {
    disconnect()
  }, [])
  function saveData() {
    localStorage.signData = JSON.stringify({
      blockchain: 1,
      signatory: address,
      signature: signMessageData,
      nonce: nonce,
    } as TSignupBody)
  }
  useEffect(() => {
    const signUserMessage = async () => {
      if (isConnected && !isLoading) {
        try {
          const { data } = await getNonce()
          nonce = data.data.nonce
          const msg = `By Signing, You Agree To Our Terms Of Use And Privacy Policy: ${nonce}`
          signMessage({
            message: msg,
          })
        } catch (error) {
          console.error('Error fetching nonce or signing message:', error)
        }
      }
    }
    signUserMessage()
  }, [isConnected, isLoading])

  useEffect(() => {
    if (isConnected && signMessageData) {
      getStateOfUser(address as string).then(async (res) => {
        if (!res.data.data.exists && address) {
          if (!isAuth) {
            saveData()
            localStorage.account = JSON.stringify({
              signatory: address,
              signature: signMessageData,
              nonce: nonce,
            })
            navigate(`/${AppRoute.ALREADY_SIGN}`)
          } else {
            await redirect()
          }
        } else {
          await redirect()
        }
      })
    }
  }, [isConnected, signMessageData])
  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <div className={styles.connectWalletContainer}>
          <div className={styles.titleWrapper}>
            {isAuth && (
              <Typography variant="h1" className={styles.title}>
                Please connect your previous wallet
              </Typography>
            )}
            {!isAuth && (
              <Typography variant="h1" className={styles.title}>
                Connect a Wallet
              </Typography>
            )}
          </div>
          <div className={styles.connectorsList}>
            {providers.map((connector) => (
              <ListButton
                title={connector.name}
                key={connector.uid}
                iconName={connector.name as any}
                onClick={() => connect({ connector, chainId })}
              />
            ))}
          </div>
        </div>
      </QueryClientProvider>
    </WagmiProvider>
  )
}
export { ConnectWallet }
export { wagmiConfig }
