import { FC, useEffect, useMemo, useState } from 'react'

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import cn from 'classnames'
import moment from 'moment/moment'

import tableStyles from '@/components/pending-orders/table-styles.module.scss'
import { RowLoadingPlaceholder } from '@/components/row-loading-placeholder'
import { ButtonIcon, Icon } from '@/libs/common'
import { IconName, Sorting } from '@/libs/enums'
import { ValueOf } from '@/libs/types'
import { useAppSelector } from '@/store'

import { Row } from './libs/components/row'
import styles from './styles.module.scss'

const OrdersHistoryTable: FC = () => {
  const [rows, setRows] = useState([])
  const [sorting, setSorting] = useState<ValueOf<typeof Sorting>>(Sorting.DESC)
  const [keySort, setKeySorting] = useState('d')
  const userHistory = useAppSelector((state) => state.favoritesTokens.orderHistory)
  useEffect(() => {
    if (userHistory) {
      const mutableUserHistory = [...userHistory]
      setRows(
        mutableUserHistory.sort((a: any, b: any) => moment(b.d).valueOf() - moment(a.d).valueOf()),
      )
    }
  }, [])

  const handleSort = (key: string) => {
    setKeySorting(key)
    setSorting((sorting) => {
      return sorting === Sorting.ASC && key === keySort ? Sorting.DESC : Sorting.ASC
    })
  }

  const sortedRows = useMemo(() => {
    return rows
      .filter((row: any) => +row.ba !== 0)
      .sort((a: any, b: any) => {
        const aValue = a[keySort]?.u ?? a[keySort]
        const bValue = b[keySort]?.u ?? b[keySort]

        const dateA = new Date(aValue)
        const dateB = new Date(bValue)

        if (!isNaN(dateA.getTime()) && !isNaN(dateB.getTime())) {
          return sorting === Sorting.ASC
            ? dateA.getTime() - dateB.getTime()
            : dateB.getTime() - dateA.getTime()
        }

        if (typeof aValue === 'string' && typeof bValue === 'string') {
          return sorting === Sorting.ASC
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue)
        }

        const numA = parseFloat(aValue)
        const numB = parseFloat(bValue)

        if (!isNaN(numA) && !isNaN(numB)) {
          return sorting === Sorting.ASC ? numA - numB : numB - numA
        }

        return 0
      })
  }, [rows, sorting, keySort])
  return (
    <div>
      <TableContainer className={styles.container}>
        <Table
          classes={styles}
          aria-label="collapsible table"
          size="small"
          className={styles.container}
        >
          <TableHead>
            <TableRow className={cn(styles.tableHead, styles.headRow)}>
              <TableCell className={cn(tableStyles.cell, tableStyles.arrowCeil)}></TableCell>

              <TableCell
                className={cn(styles.cell, styles.headCell, styles.icon, styles.wallet)}
                align="center"
              >
                <Icon name={IconName.WALLET} />
              </TableCell>

              <TableCell className={cn(styles.cell, styles.headCell, styles.type)} align="center">
                <div>
                  Type
                  <ButtonIcon
                    icon={
                      keySort === 'ty' && sorting === Sorting.ASC
                        ? IconName.SORT_ASC
                        : IconName.SORT_DESC
                    }
                    onClick={() => handleSort('ty')}
                  />{' '}
                </div>
              </TableCell>

              <TableCell className={cn(styles.cell, styles.headCell, styles.token)}>
                <div>
                  Token
                  <ButtonIcon
                    icon={
                      keySort === 'tn' && sorting === Sorting.ASC
                        ? IconName.SORT_ASC
                        : IconName.SORT_DESC
                    }
                    onClick={() => handleSort('tn')}
                  />
                </div>
              </TableCell>

              <TableCell className={cn(styles.cell, styles.headCell, styles.amount)}>
                <div>
                  Amount
                  <ButtonIcon
                    icon={
                      keySort === 'tam' && sorting === Sorting.ASC
                        ? IconName.SORT_ASC
                        : IconName.SORT_DESC
                    }
                    onClick={() => handleSort('tam')}
                  />
                </div>
              </TableCell>

              <TableCell className={cn(styles.cell, styles.headCell, styles.date)}>
                <div>
                  Date
                  <ButtonIcon
                    icon={
                      keySort === 'd' && sorting === Sorting.ASC
                        ? IconName.SORT_ASC
                        : IconName.SORT_DESC
                    }
                    onClick={() => handleSort('d')}
                  />
                </div>
              </TableCell>

              <TableCell className={cn(styles.cell, styles.headCell, styles.status)}>
                <div>
                  Status
                  <ButtonIcon
                    icon={
                      keySort === 's' && sorting === Sorting.ASC
                        ? IconName.SORT_ASC
                        : IconName.SORT_DESC
                    }
                    onClick={() => handleSort('s')}
                  />
                </div>
              </TableCell>

              <TableCell className={cn(styles.cell, styles.headCellWidth)}></TableCell>
            </TableRow>
          </TableHead>

          <TableBody className={styles.tableBody}>
            {sortedRows ? (
              sortedRows.map((row, index) => <Row key={row.id} row={row} index={index} />)
            ) : (
              <RowLoadingPlaceholder styleValiant="lite" colSpan={8} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export { OrdersHistoryTable }
