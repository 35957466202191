import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { FormControlLabel, Grid, RadioGroup } from '@mui/material'
import Stack from '@mui/material/Stack'

import { createWebHook } from '@/api/device'
import { Button, ButtonCopy, Checkbox, Input, Typography } from '@/libs/common'
import { Radio } from '@/libs/common/radio'
import { AppRoute } from '@/libs/enums'
import { extractErrorDescription } from '@/libs/helper/extractErrorDescription.ts'
import { TWebhook } from '@/libs/types/webhooks.ts'
import { useModal } from '@/pages/modal-page/modal-page.tsx'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

const CreateWebhook: FC = () => {
  const userTemplates = useAppSelector((state) => state.user.userTemplates.buyTemplates)
  const [template, setTemplate] = useState<string | null>(
    userTemplates?.length ? userTemplates[0]?.id : null,
  )
  const [inputValue, setInputValue] = useState('')
  const [hook, setHook] = useState<TWebhook>(null)
  const [stored, setStored] = useState<boolean | any>(false)

  const { setModalProps } = useModal()

  useEffect(() => {
    setModalProps({ title: 'Create Manual Buy Webhook' })
  }, [])

  const handleTemplate = (template: string) => {
    setTemplate(template)
  }
  const navigate = useNavigate()
  const { mode } = useParams()
  const createHook = async () => {
    try {
      const { data } = await createWebHook({
        name: inputValue,
        template_id: template,
        blockchain: 1,
      })
      setModalProps({ title: 'Confirm Webhook Generation' })
      setHook(data.data)
    } catch (err) {
      extractErrorDescription(err)
    }
  }

  return (
    <Grid gap={2} container flexDirection="column">
      {!hook ? (
        <>
          <Grid gap={2} container flexDirection="column">
            <Input
              placeholder="Enter webhook name"
              label={'Webhook name'}
              className={styles.input}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </Grid>

          <Grid gap={2} container flexDirection="column">
            <Typography variant="body2" textColor="color-grey-3">
              Select linked template
            </Typography>
            {userTemplates?.length ? (
              <RadioGroup
                value={template}
                name="balance-filters"
                onChange={(_, value) => handleTemplate(value)}
                className={styles.radioGroup}
              >
                {userTemplates.map((item) => (
                  <FormControlLabel
                    key={item.id}
                    className={styles.radioButton}
                    value={item.id}
                    control={<Radio />}
                    label={
                      <Typography variant="body2" textColor="color-grey-3" textAlign="center">
                        {item.name}
                      </Typography>
                    }
                  />
                ))}
              </RadioGroup>
            ) : (
              <Typography variant="body2" textAlign="center" marginBottom={2}>
                No templates
              </Typography>
            )}
          </Grid>
          <Button styleVariant="yellow" onClick={createHook} disabled={!inputValue || !template}>
            Create Webhook
          </Button>
        </>
      ) : (
        <Grid gap={2} container flexDirection="column">
          <Grid container justifyContent="space-between">
            <Grid gap={2} flexDirection="column">
              <Typography variant="body2" textColor="color-grey-2">
                Name
              </Typography>
              <Typography variant="body2" textColor="color-grey-3">
                {inputValue}
              </Typography>
            </Grid>
            <Grid gap={2} flexDirection="column">
              <Typography variant="body2" textColor="color-grey-2">
                Linked template
              </Typography>
              <Typography variant="body2" textColor="color-grey-3">
                {userTemplates?.find((item: any) => item.id === template)?.name}
              </Typography>
            </Grid>
            <Grid></Grid>
          </Grid>
          <Grid flexDirection="column" container gap={0.5}>
            <Stack direction="row" gap={4} className={styles.infoContainerHead} alignItems="center">
              <div>
                <Typography variant="body2" textColor="color-grey-2">
                  Webhook URL
                </Typography>
                <Typography variant="body2" textColor="color-grey-3">
                  {hook.url}
                </Typography>
              </div>
              <div className={styles.btn}>
                <ButtonCopy value={hook.url} className={styles.copyBtn} />
              </div>
            </Stack>
            <Stack
              direction="row"
              className={styles.infoContainerFooter}
              gap={4}
              alignItems="center"
            >
              <div>
                <Typography variant="body2" textColor="color-grey-2">
                  Access token
                </Typography>
                <Typography variant="body2" textColor="color-grey-3">
                  {hook.token}
                </Typography>
              </div>
              <div className={styles.btn}>
                <ButtonCopy value={hook.token} className={styles.copyBtn} />
              </div>
            </Stack>
          </Grid>
          <Checkbox
            label="I’ve securely saved my access token and understand that if I forget it, I won’t be able to retrieve it."
            value={stored}
            onChange={() => setStored(!stored)}
          />

          <Button
            disabled={!stored}
            styleVariant="yellow"
            onClick={() => navigate(`${AppRoute.DASHBOARD}/${mode}`)}
          >
            Confirm
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

export { CreateWebhook }
