import { useEffect, useState } from 'react'

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import cn from 'classnames'

import { Typography } from '@/libs/common'
import { useAppSelector } from '@/store'

import { Row } from './libs/components/row'
import styles from './styles.module.scss'

const TokenHistoryTable = () => {
  const [rows, setRows] = useState([])
  const panelHistory = useAppSelector((state) => state.favoritesTokens.panelHistory)
  useEffect(() => {
    if (panelHistory) {
      const mutablePanelHistory = [...panelHistory]
      setRows(mutablePanelHistory)
    }
  }, [panelHistory])
  const isLoading = useAppSelector((state) => state.profitSell.isLoading)
  return (
    <>
      <TableContainer component={Paper} className={styles.container}>
        <Table
          classes={styles}
          aria-label="collapsible table"
          size="small"
          className={styles.table}
        >
          <TableHead className={styles.tableHead}>
            <TableRow>
              <TableCell className={cn(styles.cell, styles.arrowCeil)} />

              <TableCell
                className={cn(styles.cell, styles.headCell, styles.headCellPadding, styles.time)}
              >
                Time
              </TableCell>

              <TableCell
                className={cn(styles.cell, styles.headCell, styles.tokenCeil)}
                align="left"
              >
                Token
              </TableCell>

              <TableCell
                className={cn(styles.cell, styles.headCell, styles.spentCeil)}
                align="right"
              >
                Total Spent
              </TableCell>

              <TableCell
                className={cn(styles.cell, styles.headCell, styles.revenueCeil)}
                align="right"
              >
                Total Revenue
              </TableCell>

              <TableCell className={cn(styles.cell, styles.headCell, styles.plCeil)} align="right">
                Realized P/L
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody className={styles.tableBody}>
            {rows?.map((row, index) => <Row key={row?.id} row={row} index={index} />)}
          </TableBody>
        </Table>
      </TableContainer>

      {!rows?.length && !isLoading && (
        <div className={styles.emptyState}>
          <Typography variant="body1" textColor="color-grey-4" className={styles.message}>
            You don`t have any transactions yet
          </Typography>
        </div>
      )}

      {isLoading && (
        <div className={styles.emptyState}>
          <Typography variant="body1" textColor="color-grey-4" className={styles.message}>
            Loading...
          </Typography>
        </div>
      )}
    </>
  )
}
export { TokenHistoryTable }
