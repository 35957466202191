import { FC } from 'react'

import { Grid, TableCell, TableRow } from '@mui/material'
import cn from 'classnames'

import { TUserFavorite } from '@/api/favorites/types'
import styles from '@/components/favorites-table/styles.module.scss'
import { useSelectNewToken } from '@/hooks/useSelectNewToken.hook'
import { ButtonIcon, TokenPlaceholder, Typography } from '@/libs/common'
import { IconName } from '@/libs/enums'
import { formatNumber } from '@/libs/helper'
import { formatTokenPrice } from '@/libs/helper/formatTokenPrice'
import { useAppDispatch, useAppSelector } from '@/store'
import { removeFavoriteToken } from '@/store/slices/favorites-tokens.slice'

type TProperty = {
  row: TUserFavorite
}

const Row: FC<TProperty> = ({ row }) => {
  const networkId = useAppSelector((state) => state.chain.currentChain.indexerChainId)

  const dispatch = useAppDispatch()
  const handleRemoveFavoriteToken = () => {
    dispatch(removeFavoriteToken({ tokenAddress: row.t, networkId }))
  }

  const handleNewTokenSelection = useSelectNewToken()

  const handleChangeCurrentToken = () => {
    handleNewTokenSelection(row.t)
  }

  return (
    <TableRow className={styles.row} onClick={handleChangeCurrentToken}>
      <TableCell className={styles.cell}>
        <div className={styles.controls}>
          <ButtonIcon
            icon={IconName.CROSSED_STAR}
            onClick={(e) => {
              e.stopPropagation()
              handleRemoveFavoriteToken()
            }}
          />

          <div className={styles.iconWrapper}>
            {row.i.s ? (
              <img src={row.i.s} className={styles.icon} loading="lazy" alt={row.n} />
            ) : (
              <TokenPlaceholder size="35px" />
            )}

            {/* <img src={row.pairImg} loading="lazy" alt="network logo" className={styles.network} /> */}
          </div>

          <Grid container flexDirection="column">
            <Typography variant="body2" className={styles.symbol}>
              {row.s}
            </Typography>

            <Typography variant="body2" className={styles.name}>
              {row.n}
            </Typography>
          </Grid>
        </div>
      </TableCell>
      <TableCell className={styles.cell}>
        <Typography variant="body2" textColor="color-white-3" className={styles.cellText}>
          {formatNumber(row.p).formatted}
        </Typography>
      </TableCell>

      <TableCell className={cn(styles.cell, styles.changesColumn)}>
        <div className={styles.valueWrapper}>
          <Typography
            variant="body2"
            textColor="color-white-3"
            className={cn(styles.cellText, Number(row.pc24) > 0 ? styles.profit : styles.loss)}
          >
            {formatTokenPrice(row.pc24).formatted}%
          </Typography>
        </div>
      </TableCell>
      <TableCell className={cn(styles.cell)}>
        <Typography variant="body2" textColor="color-white-3" className={styles.cellText}>
          {/*${formatNumber(row.simulation?.data?.liquidity.pair.value_usd).formatted}*/} -
        </Typography>
      </TableCell>
      <TableCell className={cn(styles.cell)}>
        <Typography variant="body2" textColor="color-white-3" className={styles.cellText}>
          {/*{formatNumber(row.simulation?.data?.market_cap).raw}*/} -
        </Typography>
      </TableCell>
    </TableRow>
  )
}

export { Row }
