import { FC, useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { ConnectWallet } from '@/components/header/libs/components/connect-wallet'
import { SwitchChain } from '@/components/header/libs/components/switch-chain'
import { DEFAULT_TOKEN } from '@/libs/configs/default-token.config.ts'
import { AppMode, AppRoute, ETransferMode } from '@/libs/enums'
import { AddChain } from '@/pages/add-chain'
import { AddDevice } from '@/pages/add-device'
import { AlreadySign } from '@/pages/already-sign'
import { Auth } from '@/pages/auth'
import { WalletConnect } from '@/pages/connect-wallet'
import { CreateMasterPassword } from '@/pages/create-master-password'
import { Dashboard } from '@/pages/dashboard'
import { EnterCode } from '@/pages/enter-code'
import { GenerateWallets } from '@/pages/generate-wallets'
import { ImportKeys } from '@/pages/import-keys'
import { ImportWalletFile } from '@/pages/import-wallet-file'
import { MasterPasswordAdvantages } from '@/pages/master-password-advantages'
import { ModalPage } from '@/pages/modal-page'
import {
  AddNewScrape,
  AddNewScrapeGroup,
  AddToken,
  AddTokenSetup,
  ApproveSell,
  BubbleMap,
  ConfirmTransfer,
  CopyTrade,
  CopyTradeSettings,
  CopyTradeTemplate,
  CreateWebhook,
  EditWalletName,
  EditWebhook,
  GenerateNewWallet,
  HiddenHoldings,
  LicenseNewServer,
  LiteSell,
  ManualBuyTemplate,
  Telegram,
  TemplateManagement,
  TransferFromWallet,
  WalletDetails,
  WalletManagement,
} from '@/pages/modal-page/libs/components'
import { AccountLocking } from '@/pages/modal-page/libs/components/account-locking'
import { AddNewScrapeTemplate } from '@/pages/modal-page/libs/components/add-new-scrape-template'
import { Affiliates } from '@/pages/modal-page/libs/components/affiliates'
import { BuyModal } from '@/pages/modal-page/libs/components/buy-modal'
import { DepositToWallet } from '@/pages/modal-page/libs/components/deposit-to-wallet'
import { EditLimitBuy } from '@/pages/modal-page/libs/components/edit-limit-buy'
import { EditLimitSell } from '@/pages/modal-page/libs/components/edit-limit-sell'
import { EditPosition } from '@/pages/modal-page/libs/components/edit-position'
import { ExportWallets } from '@/pages/modal-page/libs/components/export-wallets'
import { FavoritesHistory } from '@/pages/modal-page/libs/components/favorites-history'
import { HoldingsModal } from '@/pages/modal-page/libs/components/holdings-modal'
import { ImportPrivateKeys } from '@/pages/modal-page/libs/components/import-private-keys'
import { ImportWallets } from '@/pages/modal-page/libs/components/import-wallets'
import { LimitReached } from '@/pages/modal-page/libs/components/limit-reached'
import { LiteBuy } from '@/pages/modal-page/libs/components/lite-buy/'
import { ManualSell } from '@/pages/modal-page/libs/components/manual-sell'
import { MasterPassword } from '@/pages/modal-page/libs/components/master-password'
import { PayForPlan } from '@/pages/modal-page/libs/components/pay-for-plan'
import { PrelaunchSnipes } from '@/pages/modal-page/libs/components/prelaunch-snipes'
import { ProfitModal } from '@/pages/modal-page/libs/components/profit-modal'
import { QuickBuySettings } from '@/pages/modal-page/libs/components/quick-buy-settings'
import { SelectWalletToDeposit } from '@/pages/modal-page/libs/components/select-wallet-to-deposit'
import { SellModal } from '@/pages/modal-page/libs/components/sell-modal'
import { SetupPriorities } from '@/pages/modal-page/libs/components/setup-priorities'
import { ShareProfit } from '@/pages/modal-page/libs/components/share-profit'
import { SnipeSettings } from '@/pages/modal-page/libs/components/snipe-settings'
import { SnipeTemplate } from '@/pages/modal-page/libs/components/snipe-template'
import { TokenInfo } from '@/pages/modal-page/libs/components/token-info-new'
import { WalletFile } from '@/pages/modal-page/libs/components/wallet-file'
import { Root } from '@/pages/root'
import { SelectPlan } from '@/pages/select-plan'
import { SetupSuccess } from '@/pages/setup-success'
import { SetupWallets } from '@/pages/setup-wallets'
import { SignUp } from '@/pages/sign-up'
import { Enable2fa } from '@/pages/sign-up/libs/components/enable-2fa'
import { SuccessPaidPlan } from '@/pages/success-paid-plan'
import { SuccessPaidPlanSuccess } from '@/pages/success-paid-plan-success'

const Layout: FC = () => {
  useEffect(() => {
    const isFirefox = navigator.userAgent.toLowerCase().includes('firefox')
    if (isFirefox) {
      document.querySelector('html')?.classList.add('firefox-styles')
    }
  }, [])

  return (
    <div>
      <Routes>
        <Route path={AppRoute.ROOT} element={<Root />} />
        <Route
          path="*"
          element={<Navigate to={`${AppRoute.DASHBOARD}/${AppMode.PRO}?token=${DEFAULT_TOKEN}`} />}
        />
        <Route element={<Auth />}>
          <Route path={AppRoute.SIGN_UP} element={<SignUp />} />
          <Route path={AppRoute.ALREADY_SIGN} element={<AlreadySign />} />
          <Route path={AppRoute.ADD_CHAIN} element={<AddChain />} />
          <Route path={AppRoute.ADD_DEVICE} element={<AddDevice />} />
          <Route path={AppRoute.ENTER_CODE} element={<EnterCode />} />
          <Route path={AppRoute.SELECT_PLAN} element={<SelectPlan />} />
          <Route path={AppRoute.SETUP_WALLETS} element={<SetupWallets />} />
          <Route path={AppRoute.SUCCESS_PAID_PLAN} element={<SuccessPaidPlan />} />
          <Route path={AppRoute.SUCCESS_PAID_PLAN_SUCCESS} element={<SuccessPaidPlanSuccess />} />
          <Route path={AppRoute.GENERATE_WALLETS} element={<GenerateWallets isModal={false} />} />
          <Route path={AppRoute.IMPORT_KEYS} element={<ImportKeys />} />
          <Route path={AppRoute.IMPORT_WALLET_FILE} element={<ImportWalletFile />} />
          <Route
            path={AppRoute.MASTER_PASSWORD_ADVANTAGES}
            element={<MasterPasswordAdvantages />}
          />
          <Route path={AppRoute.CREATE_MASTER_PASSWORD} element={<CreateMasterPassword />} />
          <Route path={AppRoute.SETUP_SUCCESS} element={<SetupSuccess />} />
          <Route path={AppRoute.ENABLE_2FA} element={<Enable2fa />} />
          <Route path={AppRoute.MODAL} element={<ModalPage />}>
            <Route path={AppRoute.WALLET_CONNECT} element={<WalletConnect />} />
          </Route>
        </Route>
        <Route path={'/ws'} element={<div />}></Route>
        <Route path={`${AppRoute.DASHBOARD}/:mode`} element={<Dashboard />}>
          <Route path={AppRoute.MODAL} element={<ModalPage />}>
            <Route path={AppRoute.SETUP_PRIORITIES} element={<SetupPriorities />} />
            <Route path={AppRoute.APPROVE_SELL} element={<ApproveSell />} />
            <Route path={AppRoute.WALLET_MANAGEMENT} element={<WalletManagement />} />
            <Route path={AppRoute.CREATE_WEBHOOK} element={<CreateWebhook />} />
            <Route path={AppRoute.EDIT_WEBHOOK} element={<EditWebhook />} />
            <Route path={AppRoute.BUBBLE_MAP} element={<BubbleMap />} />
            <Route
              path={`${AppRoute.EDIT_WALLET_NAME}/:walletAddress`}
              element={<EditWalletName />}
            />
            <Route path={AppRoute.ENABLE_2FA} element={<Enable2fa settings />} />
            <Route path={AppRoute.MASTER_PASSWORD} element={<MasterPassword />} />
            <Route path={`${AppRoute.WALLET_DETAILS}/:publicKey`} element={<WalletDetails />} />
            <Route path={`${AppRoute.HIDDEN_HOLDINGS}/:publicKey`} element={<HiddenHoldings />} />
            <Route
              path={`${AppRoute.TRANSFER}/:publicKey`}
              element={<TransferFromWallet transferMode={ETransferMode.TRANSFER} />}
            />
            <Route path={AppRoute.TRANSFER_WALLETS} element={<SelectWalletToDeposit />} />
            <Route path={`${AppRoute.CONFIRM_TRANSFER}/:publicKey`} element={<ConfirmTransfer />} />
            <Route
              path={`${AppRoute.DISPERSE_MULTI}/:publicKey`}
              element={<TransferFromWallet transferMode={ETransferMode.DISPERSE} />}
            />
            <Route
              path={`${AppRoute.COLLECT_MULTI}/:publicKey`}
              element={<TransferFromWallet transferMode={ETransferMode.COLLECT} />}
            />
            <Route path={AppRoute.PRELAUNCH_SNIPES} element={<PrelaunchSnipes />} />
            <Route path={AppRoute.TOKEN_INFO} element={<TokenInfo />} />
            <Route path={`${AppRoute.SNIPE_SETTINGS}/:token`} element={<SnipeSettings />} />
            <Route path={AppRoute.SELECT_WALLET_COUNT} element={<GenerateNewWallet />} />
            <Route path={AppRoute.LIMIT_REACHED} element={<LimitReached />} />
            <Route path={AppRoute.IMPORT_PRIVATE_KEY} element={<ImportPrivateKeys />} />
            <Route path={AppRoute.IMPORT_WALLETS} element={<ImportWallets />} />
            <Route path={AppRoute.WALLET_FILE} element={<WalletFile />} />
            <Route path={AppRoute.MASTER_PASSWORD} element={<MasterPassword />} />
            <Route path={AppRoute.PROFIT_SELL_ADD_TOKEN} element={<AddToken />} />
            <Route
              path={`${AppRoute.PROFIT_SELL_ADD_TOKEN_SETUP}/:address`}
              element={<AddTokenSetup />}
            />
            <Route path={AppRoute.MANUAL_SELL} element={<ManualSell />} />
            <Route path={`${AppRoute.EDIT_POSITION}/:position`} element={<EditPosition />} />
            <Route path={AppRoute.EDIT_POSITION} element={<EditPosition />} />
            <Route path={AppRoute.SHARE_PROFIT} element={<ShareProfit />} />
            <Route path={AppRoute.MANUAL_BUY_TEMPLATE} element={<ManualBuyTemplate />} />
            <Route path={`${AppRoute.MANUAL_BUY_TEMPLATE}/:id`} element={<ManualBuyTemplate />} />
            <Route path={AppRoute.COPY_TRADE} element={<CopyTrade />} />
            <Route path={AppRoute.COPY_TRADE_SETTINGS} element={<CopyTradeSettings />} />
            <Route path={AppRoute.SNIPE_TEMPLATE} element={<SnipeTemplate />} />
            <Route path={AppRoute.COPY_TRADE_TEMPLATE} element={<CopyTradeTemplate />} />
            <Route path={AppRoute.TELEGRAM} element={<Telegram />} />
            <Route path={AppRoute.ADD_NEW_SCRAPE_GROUP} element={<AddNewScrapeGroup />} />
            <Route path={AppRoute.ADD_NEW_SCRAPE} element={<AddNewScrape />} />
            <Route path={AppRoute.ADD_NEW_SCRAPE_TEMPLATE} element={<AddNewScrapeTemplate />} />
            <Route path={AppRoute.LICENSE_NEW_SERVER} element={<LicenseNewServer />} />
            <Route path={`${AppRoute.EDIT_LIMIT_SELL}/:token`} element={<EditLimitSell />} />
            <Route path={`${AppRoute.EDIT_LIMIT_BUY}/:token`} element={<EditLimitBuy />} />
            <Route path={AppRoute.PAY_FOR_PLAN} element={<PayForPlan />} />
            <Route path={AppRoute.TEMPLATE_MANAGEMENT} element={<TemplateManagement />} />
            <Route path={AppRoute.SELECT_WALLET_TO_DEPOSIT} element={<SelectWalletToDeposit />} />
            <Route
              path={`${AppRoute.DEPOSIT_TO_WALLET}/:publicKey`}
              element={<DepositToWallet />}
            />
            <Route path={AppRoute.SWITCH_CHAIN} element={<SwitchChain />} />
            <Route path={AppRoute.CONNECT_WALLET} element={<ConnectWallet />} />
            <Route path={AppRoute.AFFILIATES} element={<Affiliates />} />
            <Route path={AppRoute.LITE_BUY} element={<LiteBuy />} />
            <Route path={AppRoute.LITE_SELL} element={<LiteSell />} />
            <Route path={AppRoute.BUY_MODAL} element={<BuyModal />} />
            <Route path={AppRoute.SELL_MODAL} element={<SellModal />} />
            <Route path={AppRoute.PROFIT_MODAL} element={<ProfitModal />} />
            <Route path={`${AppRoute.ACCOUNT_LOCKING}`} element={<AccountLocking />} />
            <Route path={`${AppRoute.EXPORT_WALLETS}`} element={<ExportWallets />} />
            <Route path={`${AppRoute.FAVORITES_HISTORY}`} element={<FavoritesHistory />} />
            <Route path={`${AppRoute.HOLDINGS}`} element={<HoldingsModal />} />
            <Route path={`${AppRoute.QUICK_BUY_SETTINGS}`} element={<QuickBuySettings />} />
          </Route>
        </Route>
      </Routes>
    </div>
  )
}

export { Layout }
