import { FC, useRef, useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import cls from 'classnames'

// import { CopyTrade } from '@/components/copy-trade'
import { DashboardComponent } from '@/components/dashboard-component'
import { DashboardComponentLite } from '@/components/dashboard-component-lite'
import { Header } from '@/components/header'
// import { Licenses } from '@/components/licenses'
import { SidebarMinimised } from '@/components/sidebar-minimised'
import { TokenList } from '@/components/token-list'
import { UserSettings } from '@/components/user-settings'
import useComponentWidth from '@/hooks/useComponentWidth'
import { useWindowDimensions } from '@/hooks/useWindowDimensions'
import { ButtonIcon, Tabs } from '@/libs/common'
import { AppMode, AppRoute, IconName, TabName } from '@/libs/enums'

import { BottomTabs } from './libs/components/bottom-tabs'
import styles from './styles.module.scss'

const Dashboard: FC = () => {
  const { mode } = useParams()
  const navigate = useNavigate()
  const ref = useRef<HTMLInputElement>(null)
  const [collapsed, setCollapsed] = useState(true)
  const [componentWidth] = useComponentWidth(ref)

  const handleOpenBuyModal = () => {
    navigate(`${AppRoute.DASHBOARD}/${AppMode.LITE}/${AppRoute.MODAL}/${AppRoute.LITE_BUY}`)
  }

  const handleOpenSellModal = () => {
    navigate(`${AppRoute.DASHBOARD}/${AppMode.LITE}/${AppRoute.MODAL}/${AppRoute.LITE_SELL}`)
  }

  const windowDimension = useWindowDimensions()
  const showTabs = windowDimension.isBottomTabsVisible && mode === AppMode.PRO
  return (
    <div className={styles.wrapper}>
      <Header setCollapsed={setCollapsed} />

      <Grid
        container
        className={cls(styles.body, mode === 'lite' && styles.bodyLite)}
        justifyContent="center"
      >
        <Grid container wrap="nowrap">
          <Grid container wrap="nowrap" className={styles.bodyContent}>
            {mode === AppMode.PRO && (
              <SidebarMinimised collapsed={collapsed} setCollapsed={setCollapsed} />
            )}

            <div className={styles.content} ref={ref}>
              <Tabs
                className={styles.tabs}
                tabContentClasses={styles.tabContent}
                name={TabName.DASHBOARD_NAV}
                // variant="standard"
                buttonsMargin={false}
                // isMain
                tabs={[
                  {
                    label: 'Dashboard',
                    content:
                      mode === AppMode.PRO ? (
                        <DashboardComponent width={componentWidth} />
                      ) : (
                        <DashboardComponentLite />
                      ),
                  },
                  {
                    label: 'Token List',
                    content: <TokenList />,
                  },
                  // { label: 'Copy trade', content: <CopyTrade /> },
                  // { label: 'Scrape', content: <CopyTrade isScrape /> },
                  { label: 'Settings', content: <UserSettings /> },
                  // { label: 'Licenses', content: <Licenses /> },
                ]}
              />
            </div>
          </Grid>
          <Outlet />
        </Grid>

        {mode === AppMode.LITE && (
          <div className={styles.footer}>
            <ButtonIcon icon={IconName.BUY} onClick={handleOpenBuyModal}>
              Buy
            </ButtonIcon>

            <ButtonIcon icon={IconName.SELL} onClick={handleOpenSellModal}>
              Sell
            </ButtonIcon>
          </div>
        )}
      </Grid>

      {showTabs && <BottomTabs />}
    </div>
  )
}

export { Dashboard }
