import { FC, useState } from 'react'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import cn from 'classnames'
import moment from 'moment'

import { TProfitSellItem } from '@/api/profit-loss/types'
import { handleROIColor } from '@/components/token-history-table/libs/helpers'
import tableStyles from '@/components/token-history-table/styles.module.scss'
import { TokenRedirect } from '@/libs/common'
import { formatNumber } from '@/libs/helper'

import styles from './styles.module.scss'

type TProps = {
  row: TProfitSellItem
  index: number
}

const Row: FC<TProps> = ({ row, index }) => {
  const [isOpen, setIsOpen] = useState(false)
  const date = moment(row.ca)
    .utc(+row.ca * 1000)
    .zone(new Date().getTimezoneOffset())
    .format('MMM D, HH:mm:ss')

  return (
    <>
      <TableRow
        className={cn(styles.tableRow, {
          [styles.backgroundEven]: index % 2 !== 0,
          [styles.backgroundOdd]: index % 2 === 0,
        })}
      >
        <TableCell className={cn(styles.cell, styles.rowCell, styles.arrowCeil)}>
          <IconButton onClick={() => setIsOpen((state) => !state)}>
            {isOpen ? (
              <KeyboardArrowUpIcon className={styles.icon} />
            ) : (
              <KeyboardArrowDownIcon className={styles.icon} />
            )}
          </IconButton>
        </TableCell>

        <TableCell
          className={cn(styles.cell, styles.tableRow, styles.paddingZero)}
          component="th"
          scope="row"
        >
          {date}
        </TableCell>

        <TableCell className={cn(styles.cell, styles.tableRow)} align="left">
          <Box display="flex" flexDirection="column">
            <TokenRedirect address={row.ta}>{row.tn || 'Token'}</TokenRedirect>
          </Box>
        </TableCell>

        <TableCell className={cn(styles.cell, styles.tableRow, styles.revenueCeil)} align="right">
          {formatNumber(row.tc.u).formatted} USD
        </TableCell>

        <TableCell className={cn(styles.cell, styles.tableRow, styles.revenueCeil)} align="right">
          {formatNumber(row.trr.u).formatted} USD
        </TableCell>

        <TableCell
          className={cn(
            styles.cell,
            styles.tableRow,
            styles.colorGreen,
            styles[handleROIColor(row.trr.p)],
          )}
          align="right"
        >
          <Box display="flex" flexDirection="column">
            <div>{formatNumber(row.trr.u).formatted} USD</div>
            <div>ROI {row.trr.p === null ? '-' : <>{formatNumber(row.trr.p).formatted}%</>}</div>
          </Box>
        </TableCell>
      </TableRow>

      <TableRow className={styles.backgroundInner}>
        <TableCell
          className={cn(styles.cell, styles.noPadding)}
          sx={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={8}
        >
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <Box width={'100%'}>
              <Table classes={styles} size="small" aria-label="purchases">
                <TableHead className={styles.tableHead}>
                  <TableRow>
                    <TableCell className={cn(styles.cell, styles.arrowCeil, styles.paddingZero)} />

                    <TableCell
                      className={cn(styles.cell, styles.time, styles.paddingZero)}
                    ></TableCell>

                    <TableCell
                      className={cn(styles.cell, styles.paddingZero, styles.tokenCeil)}
                      align="left"
                    ></TableCell>

                    <TableCell
                      className={cn(styles.cell, styles.paddingZero, styles.spentCeil)}
                      align="right"
                    ></TableCell>

                    <TableCell
                      className={cn(styles.cell, styles.paddingZero, styles.revenueCeil)}
                      align="right"
                    ></TableCell>

                    <TableCell
                      className={cn(styles.cell, styles.paddingZero, styles.plCeil)}
                      align="right"
                    ></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {row.po.map((wallet: any, index: any) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          className={cn(styles.cell, tableStyles.arrowCeil)}
                          component="th"
                          scope="row"
                        ></TableCell>

                        <TableCell
                          className={cn(styles.cell, styles.tableRow, styles.paddingZero)}
                          component="td"
                          scope="row"
                        >
                          {wallet.wn || ''}
                        </TableCell>

                        <TableCell className={cn(styles.cell, styles.tableRow)}></TableCell>

                        {/* <TableCell
                          className={cn(styles.cell, tableStyles.arrowCeil)}
                          component="th"
                          scope="row"
                        ></TableCell> */}

                        <TableCell className={cn(styles.cell, styles.tableRow)} align="right">
                          {formatNumber(wallet.c.u).formatted} USD
                        </TableCell>

                        <TableCell
                          className={cn(styles.cell, styles.tableRow, styles.revenueCeil)}
                          align="right"
                        >
                          {formatNumber(wallet.rr.u).formatted} USD
                        </TableCell>

                        <TableCell
                          className={cn(
                            styles.cell,
                            styles.colorGreen,
                            styles.tableRow,
                            styles[handleROIColor(wallet.rr.p)],
                          )}
                          align="right"
                        >
                          <Box display="flex" flexDirection="column">
                            <div>{formatNumber(wallet.so.u).formatted}USD</div>
                            <div>
                              ROI{' '}
                              {wallet.rr.p === null ? (
                                '-'
                              ) : (
                                <>{formatNumber(wallet.rr.p).formatted}%</>
                              )}
                            </div>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export { Row }
